<div *ngIf="caso">
    <!-- <div class="row p-2">
        <div class="col-md-6">
            <img src={{logoUrl}} width="100px">
        </div>
    
        <div class="col-md-6 text-right">
            <p class="font-weight-bold mb-1 h1">Relatório de Planejamento Contributivo Previdenciário
            </p>
            <p class="mb-1 h2">Cliente {{ calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}</p>
        </div>
    </div> -->
    
    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1 text-default">Planejamento Contributivo Previdenciário</span>
        </div>
    </div>
    
    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-2 text-default">Dados do Planejamento</span>
        </div>
    </div>
    
    <div class="row pt-2 pb-6 p-2">
        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Cliente</p>

            
            <p class="mb-1 ">
                <!-- <span class="text-default h1">Nome </span> -->
                <span class="display-2 fw-lighter">
                    {{calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}
                </span>
            </p>


            <p class="mb-1 h1"><span class="text-default h1">CPF </span>
                <span >
                    {{calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.cpf}}
                </span>
                </p>
            <p class="mb-1 h1"><span class="text-default h1">Sexo </span>
                {{calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo }}</p>
            <p class="mb-1 h1"><span class="text-default h1">Data Nascimento </span>
                {{calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}
            </p>
    
            <p class="mb-1 h1"><span class="text-default h1">Idade </span>
                {{calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.idade.porExtenso}}</p>
    
        </div>
    
        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Resultado</p>
            <p class="mb-1 h1"><span class="text-default h1">Data do Planejamento </span>
                {{calculoPlanejamentoKertzman.data | date : 'dd/MM/yyyy'}}</p>

            <p class="mb-1 h1">
                <span class="text-default h1">Regra </span><span>
                    {{caso.qualificacaoReferencia}}
                </span>
            </p>

            <p class="mb-1 h1">
                <span class="text-default h1">Salário de Base de Contribuição </span><span>
                    {{caso.baseContributiva | currency}}
                </span>
            </p>

            <p class="mb-1 h1">
                <span class="text-default h1">Alíquota </span><span>
                    {{caso.aliquotaContributiva * 100 | number : '1.0-0'}} %
                </span>
            </p>


            <p class="mb-1 h1">
                <span class="text-default h1">Valor do Benefício </span><span>
                    {{caso.valorBeneficio | currency}}
                </span>
            </p>

            <p class="mb-1 h1">
                <span class="text-default h1">Lucro Acumulado (ROI) </span><span>
                    {{caso.retornoTotalInvestimentoRoi | currency}}
                </span>
            </p>

            <p class="mb-1 h1">
                <span class="text-default h1">Data Prevista</span><span>
                    {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}
                </span>
            </p>
    
        </div>
    
    </div>





    <div *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio">
        
        
        <app-destaque-kertzman [visualizarPdf]="false"  #destaqueKertzman *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-destaque-kertzman>
    
        <app-variacao-contribuicoes-originais-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-contribuicoes-originais-simulacao-kertzman>
    
        <app-variacao-rmi-simulacao-kertzman-chart  [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-variacao-rmi-simulacao-kertzman-chart>
    
        <app-variacao-payback-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-payback-simulacao-kertzman>
    
        <app-variacao-taxa-payback-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-variacao-taxa-payback-simulacao-kertzman>
    
        <app-variacao-lucro-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-lucro-simulacao-kertzman>

        

        <app-comparativo-capitalizacao-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-comparativo-capitalizacao-kertzman>


        
        <app-retorno-financeiro-beneficio-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-retorno-financeiro-beneficio-kertzman>
        <app-imposto-renda-contribuinte-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-imposto-renda-contribuinte-kertzman>
        
    
    </div>
    
    
    
    
    
    
    
    <!-- <app-destaque-kertzman [calculoPlanejamentoKertzman]="calculoPlanejamentoKertzman"></app-destaque-kertzman>
    
    <app-variacao-contribuicoes-originais-simulacao-kertzman [visualizarPdf]="false" *ngIf="casoMelhor"
        [caso]="casoMelhor"></app-variacao-contribuicoes-originais-simulacao-kertzman>
    

        <app-variacao-rmi-simulacao-kertzman-chart *ngIf="casoMelhor" [caso]="casoMelhor" [visualizarPdf]="false"
            [calculoPlanejamentoKertzman]="calculoPlanejamentoKertzman"></app-variacao-rmi-simulacao-kertzman-chart>

    
    
    <app-variacao-payback-simulacao-kertzman *ngIf="simulacoes" [visualizarPdf]="false" [caso]="casoMelhor"
        [calculoPlanejamentoKertzman]="calculoPlanejamentoKertzman"></app-variacao-payback-simulacao-kertzman>
    <app-variacao-taxa-payback-simulacao-kertzman *ngIf="simulacoes" [visualizarPdf]="false" [caso]="casoMelhor"
        [calculoPlanejamentoKertzman]="calculoPlanejamentoKertzman"></app-variacao-taxa-payback-simulacao-kertzman>
    
    <app-variacao-lucro-simulacao-kertzman *ngIf="simulacoes" [visualizarPdf]="false" [caso]="casoMelhor"
        [calculoPlanejamentoKertzman]="calculoPlanejamentoKertzman"></app-variacao-lucro-simulacao-kertzman>
    
    
    
    
    
    
    
    
    
    
    
    
    <app-caso-planejamento-previdenciario [caso]="casoMelhor" [limiteTabelaProgressaoCapitalizacao]="200"
        [visualizarProgressaoCapitalizacaoTabela]="false"
        [visualizarProgressaoCapitalizacao]="true"></app-caso-planejamento-previdenciario>
    
    
    <app-quadro-beneficio *ngIf="casoMelhor.beneficio" [beneficio]="casoMelhor.beneficio"></app-quadro-beneficio> -->
    
    
    
</div>