<div *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio">

    <div [className]="aguarde ? 'visible': 'invisible'">
        <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
    </div>

    <div [className]="!aguarde ? 'visible': 'invisible'">
        <app-destaque-kertzman [visualizarPdf]="true" (gerarPdfEvent)="gerarPDF($event)" #destaqueKertzman *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-destaque-kertzman>

        <app-variacao-contribuicoes-originais-simulacao-kertzman [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-contribuicoes-originais-simulacao-kertzman>

        <div class="row">
            <div class="col-6">
                <app-variacao-rmi-simulacao-kertzman-chart [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio" (recalcularOtimizacaoEspecificaEvent)="recalcularOtimizacaoEspecifica($event)"></app-variacao-rmi-simulacao-kertzman-chart>

            </div>
            <div class="col-6">
                <app-variacao-payback-simulacao-kertzman [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-payback-simulacao-kertzman>
                
            </div>
        </div>


        <div class="row">
            <div class="col-6">
                <app-variacao-taxa-payback-simulacao-kertzman [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-taxa-payback-simulacao-kertzman>

            </div>
            <div class="col-6">
                <app-variacao-lucro-simulacao-kertzman [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-lucro-simulacao-kertzman>
                
            </div>
        </div>
        

        <app-comparativo-capitalizacao-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-comparativo-capitalizacao-kertzman>


        <!-- <app-caso-planejamento-previdenciario [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-caso-planejamento-previdenciario> -->
        <app-retorno-financeiro-beneficio-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-retorno-financeiro-beneficio-kertzman>
        <app-imposto-renda-contribuinte-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-imposto-renda-contribuinte-kertzman>

        <!-- <app-relatorio-beneficio [beneficio]="calculoPlanejamentoKertzman.otimizacao.beneficio"></app-relatorio-beneficio>  -->
    </div>

</div>