<div class="row ">
  <div class="col">
    <h5 class=" h3 mb-0 linha-quadro">Atividade Principal
      <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroAtividadePrincipal" aria-expanded="false" aria-controls="helpQuadroAtividadePrincipal"></i>
    </h5>

    <div class="collapse" id="helpQuadroAtividadePrincipal">

      <div class="row py-4 d-flex ">

        <div class="col-3 py-2 d-flex">
          <div class="row bg-secondary rounded m-2">
            <div class="col d-flex p-2">

              <p class=" p-2 lh-1 text-justify">
                Projeção das contribuições da atividade principal conforme aliquota, salário e periodicidade selecionados.
              </p>

            </div>
            <div class="mt-auto pb-2 text-right">
              <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>

</div>

<div class="py-4">
  <div class="row py-2">
    <div class="col-12">

      <h5 class=" card-title text-uppercase mb-0 text-default">
        Opções para Alíquota e Salário
      </h5>

      <div class="form-group w-100 mb-3 py-2">
        <div class="form-inline d-flex align-items-center">
          <div class="btn-group w-100" btnRadioGroup (ngModelChange)="atualizarAliquota($event)" [(ngModel)]="opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo">
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="perfisContributivosType.SALARIOMINIMO11">11% Sob o Salário Mínimo</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="perfisContributivosType.SALARIOTETO11">11% Sob o Teto</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="perfisContributivosType.SALARIOMINIMO20">20% Sob o Salário Mínimo</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="perfisContributivosType.SALARIOTETO20">20% Sob o Teto</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="perfisContributivosType.OUTROSVALORES">Outros Valores</label>
          </div>
        </div>
      </div>

    </div>
    <div class="col-4">
      <h5 class=" card-title text-uppercase mb-0 text-default">
        Alíquota Selecionada
      </h5>
      <input [disabled]="opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo != perfisContributivosType.OUTROSVALORES" [(ngModel)]="opcoesPlanejamento.edicaoSalarioAliquota.aliquota" [inputMask]="aliquotaInputMask" class=" form-control text-left"
        placeholder="0 %" type="text" />
    </div>
    <div class="col-4">
      <h5 class=" card-title text-uppercase mb-0 text-default">
        Salário Base Selecionado
      </h5>
      <input (blur)="atualizarOutrosValoresAliquotaSalario()" [disabled]="opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo != perfisContributivosType.OUTROSVALORES" [(ngModel)]="opcoesPlanejamento.edicaoSalarioAliquota.salario" [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" />
    </div>
    <!-- <div *ngIf="atualizarOutrosValores" class="col-4">
      <h5 class=" card-title text-uppercase mb-0 text-default">
        Alíquota e Salário
      </h5>
      <button (click)="atualizarOutrosValoresAliquotaSalario()" class="btn btn-lg btn-outline-black mb-0"><i class="bi bi-arrow-clockwise  p-0 m-0"></i> atualizar com valores inseridos</button>
    </div> -->
  </div>

</div>

<div *ngIf="!esconderOpcaoQuantidadeContribuicoes">
  <!-- <h5 class=" h3 mb-0 linha-quadro">Quantidade de Contribuições</h5> -->

  <div class="row py-4">

    <div class="col-12">
      <h5 class=" card-title text-uppercase mb-0 text-default">
        Opções para Quantidade de Contribuições
      </h5>

      <div class="form-group w-100 mb-3 py-2">
        <div class="form-inline d-flex align-items-center">
          <div class="btn-group w-100" btnRadioGroup (ngModelChange)="atualizarOpcaoNumeroContribuicoesPlanejamento($event)" [(ngModel)]="opcoesPlanejamento.opcaoNumeroContribuicoes">
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="opcoesNumeroContribuicoesPlanejamentoType.COMPLETO">Até a data da aposentadoria</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="opcoesNumeroContribuicoesPlanejamentoType.BASICO">Apenas o necessário para cumprimento dos requisitos</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="opcoesNumeroContribuicoesPlanejamentoType.MANTERQUALIDADESEGURADO">Manter a qualidade de segurado</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="opcoesNumeroContribuicoesPlanejamentoType.PERIODOMESES">Contribuição períodica</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="opcoesNumeroContribuicoesPlanejamentoType.ESPECIFICO">Total exato de contribuiçoes</label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-4" [ngClass]="opcoesPlanejamento.opcaoNumeroContribuicoes == opcoesNumeroContribuicoesPlanejamentoType.PERIODOMESES ? 'visible': 'invisible'">
      <h5 class=" card-title text-uppercase mb-0 text-default">
        Fazer uma contribuição a cada '{{opcoesPlanejamento.periodoMeses}}' meses
      </h5>
      <input [disabled]="opcoesPlanejamento.opcaoNumeroContribuicoes != opcoesNumeroContribuicoesPlanejamentoType.PERIODOMESES" [(ngModel)]="opcoesPlanejamento.periodoMeses" class=" form-control text-left"
        placeholder="0 meses" type="text" />
    </div>
    <div class="col-4" [ngClass]="opcoesPlanejamento.opcaoNumeroContribuicoes == opcoesNumeroContribuicoesPlanejamentoType.ESPECIFICO ? 'visible': 'invisible'">
      <h5 class=" card-title text-uppercase mb-0 text-default">
        Quantidade específica de contribuições
      </h5>
      <input (blur)="atualizarOutrosValoresAliquotaSalario()" [disabled]="opcoesPlanejamento.opcaoNumeroContribuicoes != opcoesNumeroContribuicoesPlanejamentoType.ESPECIFICO" [(ngModel)]="opcoesPlanejamento.numeroContribuicoes" class=" form-control text-left" placeholder="0 contribuições" type="text" />
    </div>

    <div *ngIf="visualizarEditorNumeroContribuicoes" class="row py-2 justify-content-end">
      <div class="col-2">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Número de Contribuições Selecionado
        </h5>
        <input [inputMask]="numeroContribuicoesMask" class=" form-control text-left"
          placeholder="0" type="text" [(ngModel)]="numeroContribuicoesSelecionado" />
      </div>
    </div>

  </div>
</div>

<div class="py-4">
  <app-add-atividade *ngIf="opcoesPlanejamento" [opcoesPlanejamento]="opcoesPlanejamento"></app-add-atividade>
</div>

<div class="row ">
  <div class="col">
      <h5 class=" h3 mb-0 linha-quadro">Comparativo de Capitalização
          <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroCapitalizacao" aria-expanded="false" aria-controls="helpQuadroCapitalizacao"></i>
      </h5>

      <div class="collapse" id="helpQuadroCapitalizacao">

          <div class="row py-4 d-flex ">

              <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2">
                      <div class="col d-flex p-2">

                          <p class=" p-2 lh-1 text-justify">
                              Informe uma rentabilidade de capitalização que será comparada com o investimento em cada benefício simulado
                          </p>

                      </div>
                      <div class="mt-auto pb-2 text-right">
                          <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                      </div>

                  </div>

              </div>

          </div>

      </div>
  </div>

</div>
<div class="row py-4">
  <div class="col-4">
    <h5 class=" mb-0 text-default">
      TAXA DE RETORNO a.a.
    </h5>

    <input [(ngModel)]="opcoesPlanejamento.produtoFinanceiro.taxaRetorno" [inputMask]="aliquotaInputMask" class=" form-control text-left"
      placeholder="0,00%" type="text" />

  </div>

  <div class="col-4">
    <h5 class="text-uppercase mb-0 text-default">
      Taxa de Desconto da Tributação
    </h5>

    <input [(ngModel)]="opcoesPlanejamento.produtoFinanceiro.taxaTributacao" [inputMask]="aliquotaInputMask" class=" form-control text-left"
      placeholder="0,00%" type="text" />

  </div>

  <div class="col-4">
    <h5 class="text-uppercase mb-0 text-default">
      Taxa de Inflação Projetada teste
    </h5>

    <input [(ngModel)]="opcoesPlanejamento.produtoFinanceiro.taxaInflacao" [inputMask]="aliquotaInputMask" class=" form-control text-left"
      placeholder="0,00%" type="text" />

  </div>

</div>

<div class="row py-4">

  <div class="row py-4">
    <div class="col-12">
      <h5 class="clicavel h3 mb-0 linha-quadro">
        Imposto de Renda
      </h5>

      <div class="form-group w-100 mb-3 py-2">
        <div class="form-inline d-flex align-items-center">
          <div class="btn-group w-100" btnRadioGroup (ngModelChange)="atualizarImpostoRenda($event)" [(ngModel)]="opcoesPlanejamento.impostoRenda.investimento">
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Considerar custo de Imposto de Renda no total do investimento</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Não considerar o Imposto de Renda no valor total do investimento</label>

          </div>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-4">
          <h5 class=" mb-0 text-default">
            NÚMERO DE DEPENDENTES
          </h5>
          <input [(ngModel)]="opcoesPlanejamento.impostoRenda.dependentes" class=" form-control text-left" placeholder="0" type="text" />

        </div>

        <div class="col-4">
          <h5 class="text-uppercase mb-0 text-default">
            DESPESA MENSAL COM SAÚDE
          </h5>
          <input [(ngModel)]="opcoesPlanejamento.impostoRenda.saude" [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="0" type="text" />
        </div>

        <div class="col-4">
          <h5 class="text-uppercase mb-0 text-default">
            DESPESA MENSAL COM EDUCAÇÃO
          </h5>
          <input [(ngModel)]="opcoesPlanejamento.impostoRenda.educacao" [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="0" type="text" />
        </div>

      </div>

    </div>

  </div>

</div>

<!-- <app-nova-contribuicao-especifica #contribuicoesEspecificas></app-nova-contribuicao-especifica> -->