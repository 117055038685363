import { Pipe, PipeTransform } from '@angular/core';
import { OpcoesNumeroContribuicoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';

@Pipe({
  name: 'frequencia',
  
})
export class FrequenciaPipe implements PipeTransform {

  transform(value: OpcoesNumeroContribuicoesPlanejamento, ...args: unknown[]): string {

    let abrev:string = "";
    switch (value) {
      case OpcoesNumeroContribuicoesPlanejamento.COMPLETO:
        abrev = "[M.C.]"
        
        break;

        case OpcoesNumeroContribuicoesPlanejamento.BASICO:
        abrev = "[M.N.]"
        break;
    
      default:
        break;
    }
    return abrev;
  }

}
