
<div class="row flex d-flex align-items-top justify-content-end">
    <div class="col">
        <input [(ngModel)]="titulo" placeholder="Editar nome do cálculo" #inputTitulo id="inputTitulo" type="text" class="tituloedit p-0 m-0 lh-1 fw-lighter" (input)="edicaoTitulo($event)" (blur)="blurEditorTitulo($event)" (focus)="focoEditorTitulo($event)">
        <!-- <i role="button" class="comando bi bi-pencil h6 align-self-end " id="editortitulo" (click)="setFocusEditorTitulo($event)"  ></i> -->
        <!-- <p class="h6 p-0 m-0 d-block">
            referência do cálculo - clique para editar
        </p> -->

    </div>

    <div class="col-auto align-items-center d-flex">
        <app-aguarde-config></app-aguarde-config>

    </div>
    <div class="col-auto px-0 mx-1 text-center">
        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" (click)="cancelar()" container="body" ngbTooltip="Fechar" triggers="hover"></i>

    </div>

    <div class="col-auto px-0 mx-1 text-center">
        <i role="button" class="comando bi bi-copy fs-6 px-0 mx-0" (click)="duplicar()" container="body" ngbTooltip="Duplicar" triggers="hover"></i>

    </div>
    <!-- <div class="col-auto px-0 mx-1 text-center">
        <i role="button" class="comando fs-6 px-0 mx-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasIvone" aria-controls="offcanvasIvone" container="body" ngbTooltip="Chat com a IvOne" triggers="hover"><span class="h6 text-white pt-1">IvOne</span></i>

    </div> -->
    <!-- <div class="col-auto px-0 mx-1 text-center">
        <i role="button" class="comando bi bi-copy fs-4 px-0 mx-0" (click)="duplicarCalculo()"></i>
        <p class="h6">
            Duplicar
        </p>
    </div> -->
    <!-- <div class="col-auto px-0 mx-1 text-center">
        <i role="button" class="comando bi bi-file-text fs-4 px-0 mx-0" (click)="salvarEdicaoCnis()"></i>
        <p class="h6 p-0 m-0">
            Salvar
        </p>
        <p class="h6 p-0 m-0">
            alterações
        </p>
        <p class="h6 p-0 m-0">
            no CNIS
        </p>
    </div> -->
    <!-- <div class="col-auto px-0 mx-1 text-center">
        <div style="visibility: hidden;">
            <i role="button" class="comando bi bi-filetype-pdf fs-6 px-0 mx-0" (click)="pdf()"></i>
            
        </div>
    </div> -->
    <div class="col-auto px-0 mx-1 text-center">
        <i role="button" class="comando bi bi-arrow-left fs-6 px-0 mx-0" (click)="anterior()" container="body" ngbTooltip="Voltar passo anterior"></i>

    </div>
    <div class="col-auto px-0 mx-1 text-center">
        <i role="button" class="comando bi bi-arrow-right fs-4" (click)="proximo()" container="body" placement="top" ngbTooltip="Próximo"></i>

    </div>
</div>


<div class="offcanvas offcanvas-end  text-bg-dark" tabindex="-1" id="offcanvasIvone" aria-labelledby="offcanvasIvoneLabel">
    <div class="offcanvas-header justify-content-between">
        <h5 class="offcanvas-title text-white font-italic" id="offcanvasIvoneLabel">IvOne</h5>
        
        <i class="bi bi-x-lg" role="button" data-bs-dismiss="offcanvas" aria-label="Close"></i>
    </div>
    <div class="offcanvas-body">

        
        <div class="row h-100  align-items-end">
            <div class="col align-text-bottom">
                <p class="h2 py-2">Uma nova experiência.</p>
                <p class="display-4 py-2"><span class="font-italic">IvOne</span> é inteligência artificial da SeniorPrev especialista em análise de oportunidades e cenários de investimento</p>
                <p class="h6 py-2">Feminino diminutivo de Ivo, que vem do anglo-saxão, e significa pessoa atenta. Nome que indica liberdade e esperança. É próprio de pessoas com grande integridade e que se regem por seus próprios critérios, os quais defendem com firmeza sempre que consideram necessário.</p>
                <p class="h4 py-2">Em breve, aqui no calculei. Fique por dentro das nossas atualizações em <a>&#64;calculeiapp</a></p>

                <div class="mb-3" data-bs-theme="dark">
                    <div class="col text-right">
                        <label for="exampleFormControlTextarea1" class="form-label">Chat <span class="font-italic">IvOne</span></label>
                    </div>
                    <textarea class="form-control" id="exampleFormControlTextarea1"  rows="6"></textarea>
                  </div>
            </div>
        </div>
        
    </div>
</div>

