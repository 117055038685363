import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { Global } from 'src/app/modelos/global';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ListarUsuariosService } from 'src/app/servicos/conta/listar-usuarios.service';
import { LogService } from 'src/app/servicos/log.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  usuario: Usuario;
  aguardeVisivel: boolean = true;

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  entries: number = 10;
  selected: any[] = [];
  temp = [];
  activeRow: any;
  rows = [];
  rowsMap = [];



  constructor(private listarUsuariosService: ListarUsuariosService,
    private calculeiApiService: CalculeiApiService,
    private configService: ConfigService,
    private datePipe: DatePipe) {



    let requestBase: RequestBase = new RequestBase();
    requestBase.usuario = Global.usuario;
    this.listarUsuariosService.listarUsuario(requestBase)
      .then(usuarios => {




        this.rows = usuarios as Usuario[];

        this.rows.sort((a, b) => { return new Date(a.dataUltimoAcesso).getTime() - new Date(b.dataUltimoAcesso).getTime() }).reverse();
        this.rowsMap = this.rows.map((prop) => { return { rowKey: prop.rowKey, nome: prop.nome, email: prop.email, dataConta: prop.dataConta, dataUltimoAcesso: prop.dataUltimoAcesso, totalAcessos: prop.totalAcessos, emailConfirmado: prop.emailConfirmado }; });
        this.temp = this.rowsMap.map((prop, key) => {
          return {
            ...prop,
            id: key
          };
        });

        this.aguardeVisivel = false;
      });
  }
  entriesChange($event) {
    this.entries = $event.target.value;
  }
  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.rowsMap.filter(function (d) {

      for (var key in d) {
        if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
          return true;
        }
      }
      return false;
    });
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  onActivate(event) {

    if (event.type === "click") {
      this.activeRow = event.row;
      this.usuario = this.rows.filter(row => row.rowKey === this.activeRow.rowKey)[0];
    }

  }

  ngOnInit() {

  }

  changeDataFimPeriodoTeste(event, usuario: Usuario) {

    var editorDataFimPeriodoTeste: HTMLInputElement = document.getElementById("editorDataFimPeriodoTeste") as HTMLInputElement;
    var novaDataFimPeriodoTeste: Date = AppComponent.stringToDate(editorDataFimPeriodoTeste.value);
    LogService.log('novadata periodo teste', novaDataFimPeriodoTeste);

    if (novaDataFimPeriodoTeste) {
      LogService.log('usuario', this.usuario.fimPeriodoTeste);
      this.usuario.fimPeriodoTeste = novaDataFimPeriodoTeste;
      LogService.log('usuario', this.usuario.fimPeriodoTeste);
      this.calculeiApiService.api('AtualizarUsuario', this.usuario).subscribe(atualizacao => {
        LogService.log('atualizacao', atualizacao);
        let usuarioAtualizacao:Usuario = atualizacao as Usuario;
        LogService.log('atualizacao data fim periodoteste', usuarioAtualizacao.fimPeriodoTeste);

      });


    }



  }

}


