<div class="row ">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">Frequência da Contribuição
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroFrequencia" aria-expanded="false" aria-controls="helpQuadroFrequencia"></i>
        </h5>

        <div class="collapse" id="helpQuadroFrequencia">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 ">
                                Escolha quantas contribuições devem ser feitas na projeção de cada regra de aposentadoria
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div class="row">
    <div class="col-auto">
        <div class="dropdown">

            <div data-bs-toggle="dropdown" aria-expanded="false" role="button">
                <div class="row">
                    <div class="col-auto text-center">
                        <i class="comando bi bi-plus-square-dotted fs-6"></i>
                        <p class="h6">
                            Incluir <br>Frequência
                        </p>
                    </div>
                </div>

            </div>

            <div #dropdownMenu id="dropAddOpcaoFrequencia" class="dropdown-menu">
                <li><a class="dropdown-item" role="button" (click)="addOpcao(opcoesNumeroContribuicoesPlanejamentoType.BASICO)">{{opcoesNumeroContribuicoesPlanejamentoType.BASICO | opcaonumerocontribuicoes}}</a></li>
                <li><a class="dropdown-item" role="button" (click)="addOpcao(opcoesNumeroContribuicoesPlanejamentoType.COMPLETO)">{{opcoesNumeroContribuicoesPlanejamentoType.COMPLETO | opcaonumerocontribuicoes}}</a></li>
                <li><a class="dropdown-item" role="button" (click)="addOpcao(opcoesNumeroContribuicoesPlanejamentoType.BIMESTRAL)">{{opcoesNumeroContribuicoesPlanejamentoType.BIMESTRAL | opcaonumerocontribuicoes}}</a></li>
                <li><a class="dropdown-item" role="button" (click)="addOpcao(opcoesNumeroContribuicoesPlanejamentoType.TRIMESTRAL)">{{opcoesNumeroContribuicoesPlanejamentoType.TRIMESTRAL | opcaonumerocontribuicoes}}</a></li>
                <li><a class="dropdown-item" role="button" (click)="addOpcao(opcoesNumeroContribuicoesPlanejamentoType.QUADRIMESTRAL)">{{opcoesNumeroContribuicoesPlanejamentoType.QUADRIMESTRAL | opcaonumerocontribuicoes}}</a></li>
                <li><a class="dropdown-item" role="button" (click)="addOpcao(opcoesNumeroContribuicoesPlanejamentoType.QUINQUIMESTRAL)">{{opcoesNumeroContribuicoesPlanejamentoType.QUINQUIMESTRAL | opcaonumerocontribuicoes}}</a></li>
                <li><a class="dropdown-item" role="button" (click)="addOpcao(opcoesNumeroContribuicoesPlanejamentoType.SEMESTRAL)">{{opcoesNumeroContribuicoesPlanejamentoType.SEMESTRAL | opcaonumerocontribuicoes}}</a></li>

                <div class="container">
                    <!-- <div class="col-12 py-2">
                            <h5 class=" card-title text-uppercase mb-0 text-default">
                                Outro valor
                            </h5>
                            <input [inputMask]="currencyInputMask" [(ngModel)]="novoSalario" placeholder="R$ 0,00" class=" form-control text-left" #inputTitulo type="text" />
                            <p class="h6">
                                Salário de contribuição
                            </p>
                        </div> -->
                    <!-- <div class="col-12 text-right py-2">
                            <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddOpcaoFrequencia()"></i>
                            <i class="comando bi bi-arrow-right fs-4" role="button" (click)="confirmarAddOpcaoFrequencia()"></i>
                        </div> -->
                </div>

            </div>
        </div>

    </div>

    <div class="col">
        <div *ngIf="!(frequencias &&  frequencias.length > 0)" class="row py-2">
            <p>
                Está vazio por aqui <i class="bi bi-moon-stars"></i>
            </p>
        </div>

        <div class="row">
            <ng-container *ngIf="frequencias">
                <ng-container *ngFor="let opcao of frequencias">
                    <div class="col-3 item d-flex">
                        <div class="row p-2">
                            <div class="col-12 bg-secondary rounded py-4">
                                <div class="row">
                                    <div class="col-auto">
                                        <p class="lh-1 fw-light">
                                            <!-- opcoes -->
                                            {{opcao | opcaonumerocontribuicoes}}
                                        </p>

                                    </div>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-auto excluir-item">
                                        <i class="bi bi-trash" role="button" (click)="excluir(opcao)"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>

            </ng-container>

        </div>

    </div>
</div>
