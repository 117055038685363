<div *ngIf="calculoMatrizPlanejamento &&  calculoMatrizPlanejamento.casos">
  <div class="row">
    <div class="col-12">
      <div class="table-wrapper">
        <table class="table table-bordered table-sm  table-responsive table-striped  table-hover align-middle overflow-auto">
          <thead>

            <tr>
              <td class="">
                <span>
                  
                </span>
              </td>

              <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                <ng-container *ngIf="!caso.filtrarMatriz">
                  <td class="">

                    <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                      {{caso.referenciaMatriz}}
                    </span>

                    <div class="dropdown">
                      <div class="dropdown-menu" [id]="'opcoesCenario'+i">
                        <div class="container">
                          <div class="row py-1">
                            <div class="col">
                              <span role="button">
                                {{caso.referenciaMatriz}}
                              </span>
                            </div>
                            <div class="col-auto">
                              <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCenario'+i)"></i>
                            </div>
                          </div>

                          <div class="py-1">
                            <div class="row">
                              <div class="col">
                                <p class="h3">Alterar Nome</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <h5 class="  text-uppercase mb-0 text-default">
                                  Nome
                                </h5>
                                <div class="row flex d-flex">
                                  <div class="col">
                                    <input class="form-control text-left" type="text" [value]="caso.referenciaMatriz" [id]="'editorTitulo' + i" />
                                  </div>
                                  <div class="col-auto align-self-center">
                                    <i class="comando bi bi-arrow-right" role="button" (click)="editarTituloCenario(caso,'editorTitulo' + i)"></i>
                                  </div>
                                </div>
                                <p class="h6">
                                  Novo nome do cenário
                                </p>

                              </div>
                            </div>
                          </div>

                          <div class="py-1">
                            <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'CASO')">
                            </app-add-marcador>
                          </div>

                          <div class="py-1">
                            <div class="row">
                              <div class="col">
                                <p class="h3">Esconder Cenário</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <i class="comando bi bi-eye-slash" role="button" (click)="esconderCenario(caso, 'opcoesCenario'+i)"></i>
                                <p class="h6">
                                  Remover o cenário da matriz
                                </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                      <span *ngFor="let marcador of caso.marcadores">
                        <span class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'CASO')" role="button" class="remover-marcador bi bi-x-lg"></i> </span>
                      </span>
                    </span>

                  </td>

                </ng-container>
              </ng-container>

            </tr>

          </thead>
          <tbody>

            <tr>

              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.aliquotaSalario">

                <th class="">
                  Alíquota / Salário
                </th>

                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoAliquota'+ i" [style.backgroundColor]=" caso.aliquotaContributivaMarcadores | tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        <span [ngSwitch]="caso.baseContributiva" class="p-0 m-0">
                          <span *ngSwitchCase="calculoMatrizPlanejamento.tetoInss">
                            <span> {{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / Teto</span>
                          </span>
                          <span *ngSwitchCase="calculoMatrizPlanejamento.salarioMinimo">
                            <span>{{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / Sal. Mín</span>
                          </span>
                          <span *ngSwitchDefault>
                            <span>{{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / {{caso.baseContributiva | currency}} </span>
                          </span>
                        </span>

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoAliquota'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo alíquota / salário
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAliquota'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ALIQUOTA')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ALIQUOTA', 'campoAliquota'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.aliquotaContributivaMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ALIQUOTA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>

              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.dataAposentadoria">
                <th class="">Data da Aposentadoria</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoDataAposentadoria'+ i" [style.backgroundColor]=" caso.dataAposentadoriaMarcadores| tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoDataAposentadoria'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Data da Aposentadoria
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoDataAposentadoria'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'DATAAPOSENTADORIA')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'DATAAPOSENTADORIA', 'campoDataAposentadoria'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.dataAposentadoriaMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'DATAAPOSENTADORIA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>

              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.regra">
                <th class="">Regra</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoRegra'+ i" [style.backgroundColor]=" caso.qualificacaoReferenciaMarcadores| tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.qualificacaoReferencia}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoRegra'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Regra
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoRegra'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'QUALIFICACAOREFERENCIA')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'QUALIFICACAOREFERENCIA', 'campoRegra'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.qualificacaoReferenciaMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'QUALIFICACAOREFERENCIA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>

              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.valorBeneficio">
                <th class="">Valor do Benefício</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoValorBeneficio'+ i" [style.backgroundColor]=" caso.valorBeneficioMarcadores | tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.valorBeneficio | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoValorBeneficio'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Valor do Benefício
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoValorBeneficio'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'VALORBENEFICIO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'VALORBENEFICIO', 'campoValorBeneficio'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.valorBeneficioMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'VALORBENEFICIO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalContribuicoes">
                <th class="">Total de Contribuições</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoTotalContribuicoes'+ i" [style.backgroundColor]=" caso.totalContributivoComplementarMesesMarcadores | tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.totalContributivoComplementarMeses}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoTotalContribuicoes'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Total de Contribuições
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTotalContribuicoes'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES', 'campoTotalContribuicoes'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.totalContributivoComplementarMesesMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                    <!-- <td>

                    <span>
                      
                    </span>
                    <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                      <span *ngFor="let marcador of caso.totalContributivoComplementarMesesMarcadores">
                        <span class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                      </span>
                    </span>

                 
                  </td> -->

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.valorContribuicao">
                <th class="">Valor da Contribuição</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoValorContribuicao'+ i" [style.backgroundColor]=" caso.valorContribuicaoComplementarMensalMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.valorContribuicaoComplementarMensal | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoValorContribuicao'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Valor da Contribuição
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoValorContribuicao'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL', 'campoValorContribuicao'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.valorContribuicaoComplementarMensalMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalInvestimento">
                <th class="">Total do Investimento</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoTotalInvestimento'+ i" [style.backgroundColor]=" caso.totalInvestimentoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.totalInvestimento | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoTotalInvestimento'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Total do Investimento
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTotalInvestimento'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TOTALINVESTIMENTO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TOTALINVESTIMENTO', 'campoTotalInvestimento'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.totalInvestimentoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALINVESTIMENTO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.roiAcumulado">
                <th class="">
                  ROI Acumulado
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoRoiAcumulado'+ i" [style.backgroundColor]=" caso.retornoTotalInvestimentoRoiMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.retornoTotalInvestimentoRoi | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoRoiAcumulado'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo ROI Acumulado
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoRoiAcumulado'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'RETORNOTOTALINVESTIMENTOROI')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'RETORNOTOTALINVESTIMENTOROI', 'campoRoiAcumulado'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.retornoTotalInvestimentoRoiMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'RETORNOTOTALINVESTIMENTOROI')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <!-- <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.taxaRetorno">
                <th class="">Taxa de Retorno</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoTaxaRetorno'+ i" [style.backgroundColor]=" caso.taxaRetornoPlanejamentoContributivoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.taxaRetornoPlanejamentoContributivo * 100 | number : '1.2-2'}} %

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoTaxaRetorno'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Taxa de Retorno
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTaxaRetorno'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO', 'campoTaxaRetorno'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.taxaRetornoPlanejamentoContributivoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr> -->

            <!-- <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.payback">
                <th class="">Payback</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoPayback'+ i" [style.backgroundColor]=" caso.tempoRetornoInvestimentoMesesAbonoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.tempoRetornoInvestimentoMesesAbono | number : '1.0-0'}} meses

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoPayback'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Payback
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoPayback'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TEMPORETORNOINVESTIMENTOMESESABONO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TEMPORETORNOINVESTIMENTOMESESABONO', 'campoPayback'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.tempoRetornoInvestimentoMesesAbonoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TEMPORETORNOINVESTIMENTOMESESABONO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr> -->

            <!-- <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.ganhoRoi">
                <th class="">Ganho no ROI</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoGanhoRoi'+ i" [style.backgroundColor]=" caso.lucroPlanejamentoContributivoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.lucroPlanejamentoContributivo | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesGanhoRoi'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Ganho ROI
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesGanhoRoi'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'LUCROPLANEJAMENTOCONTRIBUTIVO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'LUCROPLANEJAMENTOCONTRIBUTIVO', 'campoGanhoRoi'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.lucroPlanejamentoContributivoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'LUCROPLANEJAMENTOCONTRIBUTIVO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr> -->

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.percentualTeto">
                <th class="">
                  % Teto do INSS
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoPercentualTeto'+ i" [style.backgroundColor]=" caso.percentualTetoInssMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}%

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoPercentualTeto'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo % Teto do INSS
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoPercentualTeto'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'PERCENTUALTETOINSS')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'PERCENTUALTETOINSS', 'campoPercentualTeto'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.percentualTetoInssMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'PERCENTUALTETOINSS')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>


            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.acumuladoCapitalizacao">
                <th class="">
                  Acumulado Capitalização
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoAcumuladoCapitalizacao'+ i" [style.backgroundColor]=" caso.acumuladoCapitalizacaoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">

                        {{caso.taxaRetornoPrivadoAno * 100 | number :'1.2-2'}}% / {{caso.totalContributivoCapitalizadoGanhoReal | currency }}
                        <!-- {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}% -->

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoAcumuladoCapitalizacao'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  
                                  {{caso.referenciaMatriz}} opções campo Acumulado Capitalização
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAcumuladoCapitalizacao'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ACUMULADOCAPITALIZACAO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ACUMULADOCAPITALIZACAO', 'campoAcumuladoCapitalizacao'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.acumuladoCapitalizacaoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ACUMULADOCAPITALIZACAO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>


            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.acumuladoCapitalizacao">
                <th class="">
                  Valor Saque Capitalização
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoAcumuladoCapitalizacao'+ i" [style.backgroundColor]=" caso.acumuladoCapitalizacaoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">

                        {{caso.valorSaqueCapitalizacao | currency }}
                        <!-- {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}% -->

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoAcumuladoCapitalizacao'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  
                                  {{caso.referenciaMatriz}} opções campo Acumulado Capitalização
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAcumuladoCapitalizacao'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ACUMULADOCAPITALIZACAO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ACUMULADOCAPITALIZACAO', 'campoAcumuladoCapitalizacao'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.acumuladoCapitalizacaoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ACUMULADOCAPITALIZACAO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>


            

          </tbody>
        </table>

      </div>

    </div>
  </div>
</div>