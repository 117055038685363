import { Component, Input } from '@angular/core';
import { createMask } from '@ngneat/input-mask';

import { AppComponent } from 'src/app/app.component';
import { Atividade } from 'src/app/modelos/previdenciario/atividade';
import { OpcoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesplanejamento';
import { Periodicidade } from 'src/app/modelos/previdenciario/periodicidade';
import { TipoAtividade } from 'src/app/modelos/previdenciario/tipoatividade';
import { TiposVinculos } from 'src/app/modelos/previdenciario/tiposvinculos';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-add-atividade',
  templateUrl: './add-atividade.component.html',
  styleUrl: './add-atividade.component.scss'
})
export class AddAtividadeComponent {

  @Input() opcoesPlanejamento: OpcoesPlanejamento;

  atividade: Atividade;
  periodicidadeOptions = Object.values(Periodicidade);
  tiposVinculosOptions = Object.values(TiposVinculos).filter(value => typeof value != 'string' && !isNaN(Number(value)));


  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);
  currencyInputMask = createMask(AppComponent.currencyInputOptions);
  aliquotaInputMask = createMask(AppComponent.aliquotaInputMask);

  dataInicioAtividade: Date;
  constructor(private calculeiApiService: CalculeiApiService) {

    this.atividade = new Atividade();
    this.atividade.inicio = new Date();
    this.atividade.inicio = new Date();
    this.atividade.limitarDataFim = false;
    this.atividade.fim = new Date();
    this.atividade.periodicidade = Periodicidade.MENSAL;
    this.atividade.tipoVinculo = TiposVinculos.EMPREGADOOUAGENTEPUBLICO;
    this.atividade.aliquotaAutomatica = true;
    this.atividade.aliquota = 0;
    // LogService.log('tipos vinculos', Object.values(TiposVinculos))

    this.currencyInputMask.autoUnmask = true;
    this.currencyInputMask.unmaskAsNumber = true;

    this.aliquotaInputMask.oncomplete = () => {
      this.atividade.aliquota = this.atividade.aliquota / 10000;
      LogService.log('oncomplete', this.atividade.aliquota);

    }
  }

  fecharAddAtividade(){
    document.getElementById("dropAddAtividade").classList.remove('show');
  }
  confirmarAddAtividade(){}

  changeDataInicio(event) {

  }
  atualizarSalarioBlur(event) {

  }

  atualizarOpcaoDataFim(event) {

    LogService.log("opcao data fim", event);
    this.atividade.limitarDataFim = event;
    LogService.log("opcao data fim atividade", this.atividade.limitarDataFim);

    this.atividade.fim = event ? new Date() : new Date();


  }

  editarPeriodicidade(periodicidade) {
    this.atividade.periodicidade = periodicidade;
  }

  addAtividade() {

    LogService.log('opcoes planejamento add atividades', this.opcoesPlanejamento);
    let novaAtividade = JSON.parse(JSON.stringify(this.atividade));
    // novaAtividade.aliquota = novaAtividade.aliquota > 0 ? novaAtividade.aliquota / 100 : novaAtividade.aliquota;


    if (novaAtividade.aliquotaAutomatica) {
      this.calculeiApiService.api("ListarAliquotaAtividade", novaAtividade).subscribe(atividade => {

        novaAtividade = atividade;

        this.opcoesPlanejamento.atividadesSecundarias.push(novaAtividade);
        document.getElementById("dropAddAtividade").classList.remove('show');

      });
    }
    else{
      this.opcoesPlanejamento.atividadesSecundarias.push(novaAtividade);
      document.getElementById("dropAddAtividade").classList.remove('show');

    }


  }
  editarTipoVinculo(tipoVinculo: TiposVinculos) {
    this.atividade.tipoVinculo = tipoVinculo;
  }

  excluirAtividadeSecundaria(atividade: Atividade) {
    const index = this.opcoesPlanejamento.atividadesSecundarias.indexOf(atividade);
    if (index !== -1) {
      this.opcoesPlanejamento.atividadesSecundarias.splice(index, 1);
    }
  }

}
