import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { EdicaoSalarioAliquota } from 'src/app/modelos/previdenciario/edicaosalarioaliquota';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { ListarCalculoBeneficiosPlanejamentoKertzmanService } from 'src/app/servicos/calculo/previdenciario/listar-calculo-beneficios-planejamento-kertzman.service';
import { ListarGruposSumarioCalculoBeneficiosService } from 'src/app/servicos/calculo/previdenciario/listar-grupos-sumario-calculo-beneficios.service';
import { ListarMontagemCasoPlanejamentoKertzmanService } from 'src/app/servicos/calculo/previdenciario/listar-montagem-caso-planejamento-kertzman.service';
import { ListarMontagemCasoPlanejamentoService } from 'src/app/servicos/calculo/previdenciario/listar-montagem-caso-planejamento.service';
import { ListarMontagemEdicaoSalarioAliquotaService } from 'src/app/servicos/calculo/previdenciario/listar-montagem-edicao-salario-aliquota.service';
import { ListarOpcoesEnquadramentoPadraoKertzmanService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-enquadramento-padrao-kertzman.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { environment } from 'src/environments/environment';
import { ImportadorCNISComponent } from '../compartilhado/importador-cnis/importador-cnis.component';
import { EditorCNISComponent } from '../compartilhado/editor-cnis/editor-cnis.component';
import { BarraNavegacaoComponent } from 'src/app/aplicacao/compartilhado/barra-navegacao/barra-navegacao.component';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { DatePipe, Location } from '@angular/common';
import { Calculo } from 'src/app/modelos/common/calculo';
import { PreAnaliseBeneficioKertzmanComponent } from '../compartilhado/pre-analise-beneficio-kertzman/pre-analise-beneficio-kertzman.component';
import { EditorEnquadramentoContributivoKertzmanComponent } from '../compartilhado/editor-enquadramento-contributivo-kertzman/editor-enquadramento-contributivo-kertzman.component';
import { SumarioResultadoPlanejamentoKertzmanComponent } from '../compartilhado/sumario-resultado-planejamento-kertzman/sumario-resultado-planejamento-kertzman.component';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { RelatorioRMI } from 'src/app/modelos/previdenciario/relatoriormi';
import { SalvarMontagemCasoPlanejamentoKertzmanService } from 'src/app/servicos/calculo/previdenciario/salvar-montagem-caso-planejamento-kertzman.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { SeletorMatrizPlanejamentoComponent } from '../compartilhado/seletor-matriz-planejamento/seletor-matriz-planejamento.component';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { AtualizarMatrizPlanejamentoService } from 'src/app/servicos/calculo/previdenciario/atualizar-matriz-planejamento.service';
import { BaixarMatrizPlanejamentoService } from 'src/app/servicos/calculo/previdenciario/baixar-matriz-planejamento.service';
import Swal from 'sweetalert2';
import { LinkMatrizCaso } from 'src/app/modelos/previdenciario/linkmatrizcaso';

import { Global } from 'src/app/modelos/global';

import { Marcador } from 'src/app/modelos/common/marcador';

import { CnisListagemComponent } from 'src/app/aplicacao/dashboard/compartilhado-dashboard/cnis-listagem/cnis-listagem.component';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ContribuicaoEspecificaPlanejamento } from 'src/app/modelos/previdenciario/contribuicaoespecificaplanejamento';
import { ImpostoRenda } from 'src/app/modelos/common/impostorenda';
import { ProdutoFinanceiro } from 'src/app/modelos/common/produtofinanceiro';
import { OpcoesNumeroContribuicoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';
import { PerfisContributivos } from 'src/app/modelos/previdenciario/perfiscontributivos';
import { OpcoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesplanejamento';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-planejamento-kertzman',
  templateUrl: './planejamento-kertzman.component.html',
  styleUrls: ['./planejamento-kertzman.component.scss']
})
export class PlanejamentoKertzmanComponent implements OnInit, AfterViewInit, AfterViewChecked {


  aguardeVisivel: boolean;
  calculoPlanejamentoKertzman: CalculoPlanejamentoKertzman;

  @ViewChild('importadorCNIS') importadorCNIS: ImportadorCNISComponent;
  @ViewChild('editorCNIS') editorCNIS: EditorCNISComponent;
  @ViewChild('preAnalise') preAnalise: PreAnaliseBeneficioKertzmanComponent;
  @ViewChild('sumarioResultado') sumarioResultado: SumarioResultadoPlanejamentoKertzmanComponent;
  @ViewChild('editorEnquadramento') editorEnquadramento: EditorEnquadramentoContributivoKertzmanComponent;


  @ViewChild('seletorCnis') seletorCnis: CnisListagemComponent;


  cnisEditado: CNIS;
  dataCalculoEditada: Date;
  calculoArquivo: boolean;

  private stepper: Stepper;
  aguardeConfig: boolean;
  aguarde: boolean;
  atualizarCalculoCnisOpcoesModificados: boolean;
  atualizarPreAnalise: boolean;


  constructor(private location: Location, private listarCalculoResultadoService: ListarCalculoResultadoService,
    private router: Router,
    private datePipe: DatePipe, private listarMontagemCasoPlanejamentoKertzmanService: ListarMontagemCasoPlanejamentoKertzmanService,
    private listarMontagemEdicaoSalarioAliquotaService: ListarMontagemEdicaoSalarioAliquotaService,
    private salvarMontagemCasoPlanejamentoKertzmanService: SalvarMontagemCasoPlanejamentoKertzmanService,
    private listarCalculoBeneficiosPlanejamentoKertzmanService: ListarCalculoBeneficiosPlanejamentoKertzmanService,
    private listarOpcoesEnquadramentoPadraoKertzmanService: ListarOpcoesEnquadramentoPadraoKertzmanService,
    private calculeiApiService: CalculeiApiService,
    private changeDetector: ChangeDetectorRef,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private notificacoesService: NotificacoesService,
    private listarPDFService: ListarPDFService,
    private atualizarMatrizPlanejamentoService: AtualizarMatrizPlanejamentoService,
    private baixarMatrizPlanejamentoService: BaixarMatrizPlanejamentoService,
    private configService: ConfigService) {
      this.configService.getAguarde().subscribe(aguarde=>this.aguarde=aguarde);
  }


  ngAfterViewInit(): void {

    if (this.location.getState().hasOwnProperty('calculo')) {

      this.calculoPlanejamentoKertzman = this.location.getState()['calculo'] as CalculoPlanejamentoKertzman;
      this.stepper.to(5);

    } else {

      this.calculoPlanejamentoKertzman = new CalculoPlanejamentoKertzman();
      this.calculoPlanejamentoKertzman.calculoBeneficios = new CalculoBeneficios();
      this.calculoPlanejamentoKertzman.calculoBeneficios.dataCalculo = new Date();
      this.calculoPlanejamentoKertzman.nomeCalculo = "Novo cálculo Método Kertzman";
      this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.KERTZMAN;
      this.calculoPlanejamentoKertzman.data = new Date();


      this.calculoPlanejamentoKertzman.opcoesPlanejamento = new OpcoesPlanejamento();
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.edicaoSalarioAliquota = new EdicaoSalarioAliquota();
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.edicaoSalarioAliquota.aliquota = 0.20;

      this.configService.getSalarioTeto().subscribe(salarioTeto => this.calculoPlanejamentoKertzman.opcoesPlanejamento.edicaoSalarioAliquota.salario = salarioTeto);
      // console.log('salario teto', this.calculoPlanejamentoKertzman.opcoesPlanejamento.edicaoSalarioAliquota.salario);
      this.configService.getSalarioMinimo().subscribe(salarioMinimo => this.calculoPlanejamentoKertzman.opcoesPlanejamento.edicaoSalarioAliquota.salarioPiso = salarioMinimo);
      this.configService.getSalarioTeto().subscribe(salarioTeto => this.calculoPlanejamentoKertzman.opcoesPlanejamento.edicaoSalarioAliquota.salarioTeto = salarioTeto);
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.SALARIOTETO20;

      this.calculoPlanejamentoKertzman.opcoesPlanejamento.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.BASICO;
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.produtoFinanceiro = new ProdutoFinanceiro();
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.produtoFinanceiro.taxaInflacao = 0.049;
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.produtoFinanceiro.taxaRetorno = 0.13;
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.produtoFinanceiro.taxaTributacao = 0.15;

      this.calculoPlanejamentoKertzman.opcoesPlanejamento.impostoRenda = new ImpostoRenda();
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.impostoRenda.dependentes = 0;
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.impostoRenda.educacao = 0;
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.impostoRenda.investimento = false;
      this.calculoPlanejamentoKertzman.opcoesPlanejamento.impostoRenda.saude = 0;


      this.calculoPlanejamentoKertzman.opcoesPlanejamento.contribuicoesEspecificasPlanejamento = new Array<ContribuicaoEspecificaPlanejamento>();
      this.atualizarPreAnalise = true;

    }
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }


  ngOnInit(): void {

    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    this.configService.getAguarde().subscribe(status => this.aguardeConfig = status);

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {
      // You can call preventDefault to stop the rendering of your step
      // console.warn('stepper show', event.detail);

      switch (event.detail.to) {
        case 0:
          // console.log('entrar no passo seleção CNIS');
          break;
        case 1:
          if (this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Selecione um CNIS no passo 1 - Selecionar CNIS, para continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok, ir para o passo 1 - Selecionar CNIS',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }
          break;
        case 2:
          if (this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis) {
            // console.log('pre analise kertzman');
            if (this.atualizarPreAnalise) {
              this.calcularPreAnalise();
            }

          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Selecione um CNIS no passo 1 - Selecionar CNIS, para continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok, ir para o passo 1 - Selecionar CNIS',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }

          break;
        case 3:
          if (this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');

          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Selecione um CNIS no passo 1 - Selecionar CNIS, para continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok, ir para o passo 1 - Selecionar CNIS',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }

          break;
        case 4:
          if (this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
            this.calcularResultado();
          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Selecione um CNIS no passo 1 - Selecionar CNIS, para continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok, ir para o passo 1 - Selecionar CNIS',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }

          break;

        default:
          break;
      }

      //controle salvar cnis

      switch (event.detail.from) {
        case 1:
          this.editorCNIS.salvarModificacoesCnis();
          break;

        default:
          break;
      }
    });

    stepperElement.addEventListener('shown.bs-stepper', (event: CustomEvent) => {
      // console.warn('step shown mostrando', event.detail);

      // console.log('passo ', event.detail.indexStep);
      switch (event.detail.indexStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }

    });


    if (!environment.production) {
      // this.mock();
    };
  }

  mock() {
    
    
    this.listarDadosArquivoDataService.ListarArquivo("calculoplanejamentokertzman.json", request => {

      // console.log('mock calculo kertzman', request);
      this.calculoPlanejamentoKertzman = request as CalculoPlanejamentoKertzman;

      this.stepper.to(5);
    });
  }
  leituraCNISEvent(leituraCNIS: LeituraCNIS) {

    // console.log('leitura cnis event planejamento kertzman');
    this.configService.setAguarde(true);
    this.changeDetector.detectChanges()
    this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis = leituraCNIS
    setTimeout(() => {
      this.cnisAtualizado(this.calculoPlanejamentoKertzman.calculoBeneficios);
    }, 100);


    this.proximo(true);

  }



  edicaoCNISFinalizada(calculoBeneficios: CalculoBeneficios) {
    this.changeDetector.detectChanges();
    this.calculoPlanejamentoKertzman.calculoBeneficios = calculoBeneficios;

    this.aguardeVisivel = false;
    // this.preAnalise.calcularResultados();

  }


  atualizarOtimizacaoEspecifica(atualizacao: CalculoPlanejamentoKertzman) {
    LogService.log('atualizando otimizacao');
    this.atualizarCalculoCnisOpcoesModificados = true;
    this.calculoPlanejamentoKertzman = atualizacao;
    this.calcularResultado();
  }

  incluirCasoMatrizPlanejamento(incluir: boolean) {
    // this.linkMatrizPlanejamentoCaso.openModal(this.casoMelhor);
  }

  linkMatrizCasoCriado(linkMatrizCaso: LinkMatrizCaso) {

    this.configService.setAguarde(true);

    this.baixarMatrizPlanejamentoService.baixarMatrizPlanejamento(linkMatrizCaso.matriz)
      .then((matriz: MatrizPlanejamento) => {


        if (matriz.marcadores.filter(marcador => marcador.rowKey === linkMatrizCaso.caso.marcador.rowKey).length == 0) {
          matriz.marcadores.push(linkMatrizCaso.caso.marcador);
        }

        if (!matriz.casos) matriz.casos = new Array();
        matriz.casos.push(linkMatrizCaso.caso);
        this.configService.getUsuario().subscribe(usuario => matriz.usuario = usuario);

        this.atualizarMatrizPlanejamentoService.atualizarMatrizPlanejamento(matriz)
          .then((matriz: MatrizPlanejamento) => {

            this.configService.setAguarde(false);
            Swal.fire({
              title: 'Pronto! ',
              text: 'Cenário ' + linkMatrizCaso.caso.nome + ' incluido na Matriz ' + matriz.nome,
              icon: 'success',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#172b4d'

            });

          });

      });

  }

  setMarcadorCasoSelecionado(set: boolean) {
    // this.casoMelhor.calculoRowKey = this.calculoPlanejamentoKertzman.rowKey;
    // this.casoMelhor.tipoCalculo = CalculoTipos.CASOPLANEJAMENTOKERTZMAN;
    // this.setMarcadorCaso.openModal(this.casoMelhor);
  }

  setMarcadorCasoFinalizado(caso: CasoPlanejamentoPrevidenciario) {
    // this.casoMelhor = caso;
    // this.sumarioResultado.carregarDados(this.calculoPlanejamentoKertzman);

    // console.log('calculo atualizado pronto');
    // console.log(this.calculoPlanejamentoKertzman);
  }

  incluirMarcadorCnis() {
    this.editorCNIS.incluirMarcador();
  }
  excluirCnis() {

    this.aguardeVisivel = true;
    this.editorCNIS.excluirCnis();
  }
  excluirCnisFinalizado(exclui: boolean) {
    // console.log('cnis excluido - beneficios component');
    this.editorCNIS.excluirFinalizado();
    // this.seletorCnis.carregarDados();

    this.aguardeVisivel = false;
  }














  baixarPDF(navegacao: Navegacao) {

    this.gerarPDF(true);

  }

  gerarPDF(event) {

    // console.log(this.casoMelhor);
    // console.log('gerar pdf kertzman');

    LogService.log("gerar pdf kertzman", this.calculoPlanejamentoKertzman);

    this.configService.setAguarde(true);
    this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.KERTZMAN;
    this.calculoPlanejamentoKertzman.usuario = Global.usuario;

    // this.casoMelhor.tipoCalculo = CalculoTipos.CASOPLANEJAMENTOKERTZMAN;
    // this.casoMelhor.usuario = Global.usuario;

    // this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.KERTZMAN;

    // let requestClean:CalculoPlanejamentoKertzman = JSON.parse(JSON.stringify(this.calculoPlanejamentoKertzman));
    // requestClean.simulacoes = [];



    this.calculeiApiService.apiBlobServices("ListarPDF", this.calculoPlanejamentoKertzman)
    // this.calculeiApiService.apiBlob("ListarPDF", this.calculoPlanejamentoKertzman)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO-PLANEJAMENTO-CONTRIBUTIVO-KERTZMAN-' + this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
        this.configService.setAguarde(false);



      });
  }



  proximoStepper() {
    // console.log('proximo stepper');
    this.stepper.next();
  }
  anteriorStepper() {
    // console.log('anterior stepper');
    this.stepper.previous();
  }

  proximo(event) {
    this.proximoStepper()
  }
  anterior(event) {
    this.anteriorStepper()
  }
  cancelar(event) {
    // console.log('cancelar');
    this.router.navigateByUrl('/calculei/home');

  }
  pdf(event) {

    this.gerarPDF(true)

  }

  cnisAtualizado(calculoBeneficios: CalculoBeneficios) {
    // console.log('atualizando cnis beneficios');
    this.atualizarPreAnalise = true;

    this.cnisOpcoesModificados(true);
    if (this.editorCNIS) {
      this.editorCNIS.carregarDados();
    }
  }

  cnisOpcoesModificados(event) {
    // console.log('cnis Modificado set true atualizarCalculo');
    this.atualizarCalculoCnisOpcoesModificados = true;
  }

  calcularResultado() {

    if (!this.atualizarCalculoCnisOpcoesModificados) return;
    this.configService.setAguarde(true);
    this.changeDetector.detectChanges()

    // console.log('atualizando cnis');

    this.configService.getUsuario().subscribe(usuario => this.calculoPlanejamentoKertzman.usuario = usuario);
    OpcoesPlanejamento.sanitizeCurrency(this.calculoPlanejamentoKertzman.opcoesPlanejamento);

    let prepCalculoApi: CalculoPlanejamentoKertzman = JSON.parse(JSON.stringify(this.calculoPlanejamentoKertzman));
    prepCalculoApi.casos = new Array();
    prepCalculoApi.simulacoes = new Array();
    prepCalculoApi.preAnalise = null;
    prepCalculoApi.calculoBeneficios.beneficios = new Array();



    this.calculeiApiService.api("ListarMontagemCasoPlanejamentoKertzman", prepCalculoApi)
      .subscribe(calculo => {

        // console.log('calculo planejamento kertzman pronto');
        // console.log(calculo);




        this.calculoPlanejamentoKertzman = calculo as CalculoPlanejamentoKertzman;

        LogService.log('kertman pronto', this.calculoPlanejamentoKertzman);


        this.configService.setAguarde(false);
        this.changeDetector.detectChanges();
        this.atualizarCalculoCnisOpcoesModificados = false;
        this.atualizarPreAnalise = false;

      });
  }

  salvarEdicaoCnis(event) {
    this.editorCNIS.salvarModificacoesCnis();
  }

  calcularPreAnalise() {

    this.configService.setAguarde(true);
    this.calculoPlanejamentoKertzman.calculoBeneficios

    this.calculeiApiService.api("ListarCalculoBeneficiosPlanejamentoKertzman", this.calculoPlanejamentoKertzman)
      .subscribe(calculo => {
        this.calculoPlanejamentoKertzman = calculo as CalculoPlanejamentoKertzman;
        this.configService.setAguarde(false);
        this.atualizarPreAnalise = false;
      });
  }
  editarTitulo(titulo: string) {
    this.calculoPlanejamentoKertzman.nomeCalculo = titulo;
  }


}
