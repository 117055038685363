import { Component, Input } from '@angular/core';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';

@Component({
  selector: 'app-imposto-renda-contribuinte-kertzman',
  templateUrl: './imposto-renda-contribuinte-kertzman.component.html',
  styleUrl: './imposto-renda-contribuinte-kertzman.component.scss'
})
export class ImpostoRendaContribuinteKertzmanComponent {

  @Input() relatorio: RelatorioKertzman;

}
