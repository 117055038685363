<div class="row ">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">Histórico de CNIS
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroHistoricoCnis" aria-expanded="false" aria-controls="helpQuadroHistoricoCnis"></i>
        </h5>

        <div class="collapse" id="helpQuadroHistoricoCnis">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 text-justify">
                                Sempre que você importar um CNIS ele vai ficar guardado nesta área. Da próxima vez, você pode abrir o CNIS diretamente aqui do histórico, mesmo sem o arquivo original.
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-clock-history fs-3"></i>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>


<div class="row pt-4  flex d-flex justify-content-between">

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">

           
        </div>

    </div>

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
            <div *ngIf="listaLeituraCnis && listaPronta && !(listaLeituraCnis.length == 0)">
                <span class="comando bi bi-trash fs-6 " role="button" (click)="excluirTodosCNIS()" container="body" ngbTooltip="Excluir todos CNIS" triggers="hover"></span>
            </div>
            
            
        </div>
        
    </div>

</div>

<div class="py-4">
    <div [className]="!listaPronta || aguarde ? 'visible py-4' : 'invisible py-4'">
        <div class="d-flex justify-content-center">

            <app-aguarde-config [aguarde]="!listaPronta"></app-aguarde-config>

        </div>
    </div>

    <div *ngIf="listaLeituraCnis && listaPronta">
        <div *ngIf="listaLeituraCnis.length == 0" class="row">
            <div class="col-12 justify-items-center text-center">
                <span>Está vazio por aqui <i class="bi bi-moon-stars"></i></span>

            </div>
        </div>

        <div [className]="!aguarde ? 'visible' : 'invisible'">
            <div class="row d-flex ">

                <ng-container *ngFor="let leitura of listaLeituraCnis">

                    <div class="col-3 py-2 d-flex">
                        <div class="row w-100 bg-secondary rounded d-flex flex-column m-2  p-2" role="button" (click)="cardClick(leitura)">

                            <div class="col pt-2">
                                <p class="h6 lh-1 fw-lighter">{{leitura.nome}}</p>
                                <ng-container *ngIf="usuario && usuario.configuracao.anonimo">
                                    <p class="lh-1 fw-light">{{leitura?.cnis?.identificacaoFiliado?.nome | anonimo}}</p>
                                </ng-container>
                                <ng-container *ngIf="usuario && !usuario.configuracao.anonimo">
                                    <p class="lh-1 fw-light">{{leitura?.cnis?.identificacaoFiliado?.nome}}</p>
                                </ng-container>
                                
                                
                                <p class=" h5 lh-1 fw-lighter">última modificação {{leitura.dataUltimaModificacao | date }} </p>

                            </div>
                            <div class="col align-content-end text-right">
                                <i class="bi bi-trash h6 fw-lighter " role="button" (click)="excluirCNIS(leitura)"></i>
                            </div>

                        </div>

                    </div>

                </ng-container>

            </div>

        </div>

        <!-- <div class="row border rounded m-2 p-2 flex d-flex" *ngFor="let nome of listaNomes index as i;">

            <div class="col ">

                <a class="text-default" data-bs-toggle="collapse" [href]="'#' + i" role="button" aria-expanded="false" [aria-controls]="i">
                    <div class="row flex d-flex justify-content-between">
                        <div class="col">

                            <div [className]="!usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                                <p class="h2">{{nome}} </p>
                            </div>
                            <div [className]="usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                                <p class="h2">{{nome | anonimo}} </p>
                            </div>

                        </div>

                        <div class="col-auto ">

                            <div class="row d-flex">
                                <div class="col">
                                    <p class="h2">

                                    </p>
                                    <p class="h6">

                                    </p>
                                </div>

                                <div class="col">
                                    <i class="bi bi-chevron-down fs-2"></i>
                                    <i class="bi bi-chevron-up fs-2 "></i>

                                </div>
                            </div>

                        </div>

                    </div>
                </a>

                <div class="collapse" [id]="i">

                    <div class="row py-2 m-2 flex d-flex">

                        <div class="col-3 py-4" *ngFor="let leitura of leituraCnisNome(nome)">
                            <div class="row h-100 m-2 py-4 rounded bg-secondary">

                                <div class="col-12">
                                    <p class="h2">{{leitura.nome}}</p>
                                    <p class=" h6  m-0 p-0">última modificação {{leitura.dataUltimaModificacao | date }} {{leitura.dataUltimaModificacao | date : 'HH:mm' }} </p>

                                </div>

                                <div class="col-12 align-self-end">
                                    <button (click)="cardClick(leitura)" class="btn btn-outline-default">
                                        Usar este CNIS
                                    </button>
                                    <button (click)="excluirCNIS(leitura)" class="btn btn-outline-default">

                                        <i class="bi-trash"></i>
                                        Excluir
                                    </button>

                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="row py-4 m-2 ">
                        <div class="col-auto">
                            <button (click)="excluirTodosCNIS(nome)" class="btn btn-outline-default  py-2 "><i class="bi-trash"></i> Excluir todos os CNIS de {{nome | anonimo}}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div> -->

    </div>
</div>
