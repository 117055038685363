import { AnaliseBeneficio } from "./analisebeneficio";
import { CNIS } from "./cnis";
import { Contribuicao } from "./contribuicao";
import { DataTempo } from "./datatempo";
import { ExtratoBeneficio } from "./extratobeneficio";
import { Idade } from "./idade";
import { ListaReformas } from "./listareformas";
import { ListaRegras } from "./listaregras";

import { Observacao } from "./observacao";
import { RegrasContagemContribuicoes } from "./regrascontagemcontribuicoes";
import { RelacaoPrevidenciaria } from "./relacaoprevidenciaria";
import { RelatorioRMI } from "./relatoriormi";
import { StatusBeneficio } from "./statusbeneficio";
import { Tempo } from "./tempo";
import { TempoContribuicao } from "./tempocontribuicao";
import { TipoAtividade } from "./tipoatividade";
import { TipoBeneficio } from "./tipobeneficio";

export class Beneficio {

    status: StatusBeneficio;
    tipoBeneficio: TipoBeneficio;
    reforma: ListaReformas;
    regra: ListaRegras;
    atividade: TipoAtividade;
    tempoContribuicao: Tempo;
    idade: Tempo;
    idadeComplementar: Tempo;
    idadeCarencia: Tempo;
    limitadorData: Date;
    limitadorDataTempoContribuicao: Date;


    tempoCalculado: Tempo;

    tempoContribuicaoComplementar: Tempo;
    tempoContribuicaoCarencia: Tempo;
    tempoContribuicaoCarenciaMeses: Tempo;
    carenciaCumprida: Tempo;
    carenciaComplementar: Tempo;
    carenciaMeses: Tempo;
    tempoContribuicaoExcedenteCarencia: Tempo;
    tempoContribuicaoProfessor: Tempo;
    tempoContribuicaoNormal: Tempo;
    tempoContribuicaoEspecial15: Tempo;
    tempoContribuicaoEspecial20: Tempo;
    tempoContribuicaoEspecial25: Tempo;
    tempoContribuicaoEspecialTotal: Tempo;

    analiseBeneficio: AnaliseBeneficio;
    extratoBeneficio: ExtratoBeneficio;
    observacoes: Observacao[];
    pontos: number;
    pontosComplementares: number;
    pontosCarencia: number;
    pedagio: Tempo;
    relatorioRendaMensalInicial: RelatorioRMI;
    cnis: CNIS;
    dataCalculo: Date;
    contribuicoes: Contribuicao[];
    ultimaContribuicao: Contribuicao;
    qualificacao: string;

    relacaoTempoContribuicaoCarencia: number;
    relacaoIdadeCarencia: number;
    relacaoCarenciaMeses: number;

    revisaoVidaToda: boolean;


    cumpreRequisitoIdade: boolean;
    cumpreRequisitoTempoContribuicao: boolean;
    cumpreRequisitoCarencia: boolean;

    totalContribuicoesComplementarCarenciaPlanejamento: number;
    tempoComplementarPlanejamento: DataTempo;
    idadeComplementarPlanejamento: DataTempo;
    totalContribuicoesPlanejamento: number;
    tempoContribuicaoComplementarPlanejamento: Tempo;

    opcoesDescarte: RelatorioRMI[];
    maiorRMI: boolean;

    contribuicaoAnteriorReforma: boolean;
    acrescimo25Dependente: boolean;



    beneficioRegraAplicavel: boolean;

    pontosComplementaresPlanejamento: number;
    relacaoPontosCarencia: number;

    qualificacaoDetalhada: string;

    quantidadeContribuicoesAteAposentadoria: number;


    tempoContribuicaoSemDescarte: Tempo;

    dataCumprimentoIdade: Date;

    relacoesPrevidenciariasTempoValido: RelacaoPrevidenciaria[];

    qualificacaoReferencia: string;

    
    dataIdadeComplementar:Date;



    static tipoBeneficioToString(tipo: TipoBeneficio) {


        let resultado = '';
        let conversao: TipoBeneficio = TipoBeneficio[tipo.toString()];

        switch (conversao) {
            case TipoBeneficio.AposentadoriaIdade:
                resultado = 'Aposentadoria por Idade';
                break;

            case TipoBeneficio.AposentadoriaTempoContribuicao:
                resultado = 'Aposentadoria por Tempo de Contribuição';
                break;

            case TipoBeneficio.AposentadoriaEspecial:
                resultado = 'Aposentadoria Especial';
                break;

            case TipoBeneficio.AposentadoriaEspecial15:
                resultado = 'Aposentadoria Especial 15';
                break;
            case TipoBeneficio.AposentadoriaEspecial20:
                resultado = 'Aposentadoria Especial 20';
                break;
            case TipoBeneficio.AposentadoriaEspecial25:
                resultado = 'Aposentadoria Especial 25';
                break;

            case TipoBeneficio.AposentadoriaIncapacidadePermanente:
                resultado = 'Aposentadoria por Incapacidade Permanente';
                break;

            case TipoBeneficio.AposentadoriaInvalidez:
                resultado = 'Aposentadoria por Invalidez (Incapacidade Permanente)';
                break;
            case TipoBeneficio.AuxilioAcidente:
                resultado = 'Auxílio-Acidente';
                break;
            case TipoBeneficio.AuxilioDoenca:
                resultado = 'Auxílio-Doença';
                break;
            case TipoBeneficio.AuxilioReclusao:
                resultado = 'Auxílio-Reclusão';
                break;
            case TipoBeneficio.PensaoMorte:
                resultado = 'Pensão por Morte';
                break;
            case TipoBeneficio.SalarioMaternidade:
                resultado = 'Pensão por Morte';
                break;
            case TipoBeneficio.Capitalizacao:
                resultado = 'Capitalização';
                break;



            default:
                resultado = 'Tipo de Benefício não reconhecido';
                break;
        }

        return resultado;
    }


    static statusBeneficioToString(status: StatusBeneficio) {

        let resultado = '';
        let conversao: StatusBeneficio = StatusBeneficio[status.toString()];

        switch (conversao) {
            case StatusBeneficio.Apto:
                resultado = 'Apto(a)';
                break;
            case StatusBeneficio.Inapto:
                resultado = 'Não Apto(a)';
                break;

            default:
                resultado = 'Tipo de Benefício não reconhecido';
                break;
        }


        return resultado;
    }

    static regraContagemBeneficioToString(beneficio: Beneficio) {
        let resultado = '';


        let conversao: RegrasContagemContribuicoes = beneficio.relatorioRendaMensalInicial.regraContagemContribuicoes;


        switch (conversao) {
            case RegrasContagemContribuicoes.DIVISORMINIMO:
                resultado = 'Regra do Divisor Minimo'
                break;

            case RegrasContagemContribuicoes.PBC80:
                resultado = '80% das maiores contribuições';
                break;
            case RegrasContagemContribuicoes.PBC100:
                resultado = '100% das contribuições';
                break;
            case RegrasContagemContribuicoes.DESCARTES:
                resultado = 'Descartes - Regra de Ouro - &#167; 6<sup>o</sup> art. 26<sup>o</sup> da EC-103/2019 . Foram descartadas ' + beneficio.relatorioRendaMensalInicial.totalDescartesRealizados + ' contribuições';
                break;
            default:
                break;
        }

        return resultado;
    }
    static regraContagemBeneficioInfoToString(regra: RegrasContagemContribuicoes) {
        let resultado = '';


        let conversao: RegrasContagemContribuicoes = regra;


        switch (conversao) {
            case RegrasContagemContribuicoes.DIVISORMINIMO:

                resultado = 'Regra do Divisor Minimo'
                break;

            case RegrasContagemContribuicoes.PBC80:
                resultado = 'PBC 80% são as maiores contribuições do segurado referente a 80% do período contributivo, aplicável somente antes da EC 103/2019';
                break;
            default:
                break;
        }

        return resultado;
    }


    static atividadeBeneficioToString(tipo: TipoAtividade) {

        let resultado = '';

        let conversao: TipoAtividade = TipoAtividade[tipo.toString()];

        switch (conversao) {
            case TipoAtividade.Especial15:
                resultado = 'Especial 15';
                break;
            case TipoAtividade.Especial20:
                resultado = 'Especial 20';
                break;
            case TipoAtividade.Especial25:
                resultado = 'Especial 25';
                break;
            case TipoAtividade.Normal:
                resultado = 'Normal';
                break;

            default:
                resultado = 'Tipo de atividade';
                break;
        }


        return resultado;
    }



}