import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorHandler, NgModule, SecurityContext } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ToastrModule } from "ngx-toastr";
import { TagInputModule } from "ngx-chips";
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";



import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from "./components/components.module";

import { AppRoutingModule } from './app-routing.module';
import { AplicacaoModule } from './aplicacao/aplicacao.module';
import { ServicosModule } from './servicos/servicos.module';
import { OAuthModule } from "angular-oauth2-oidc";
import { PaginasModule } from './paginas/paginas.module';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';

import { CompartilhadoModule } from "./aplicacao/calculos/previdenciario/compartilhado/compartilhado.module";
import { UiSwitchModule } from "ngx-ui-switch";
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { InputMaskModule } from '@ngneat/input-mask';

import { BlogLayoutComponent } from './layouts/blog-layout/blog-layout.component';
import { MarkdownModule, SECURITY_CONTEXT } from 'ngx-markdown';


import { TabsModule, TabsetConfig } from "ngx-bootstrap/tabs";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RelatoriosModule } from './relatorios/relatorios.module';
import { ErrorIntercept } from "./servicos/instrumentacao/error.interceptor";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ErrorHandlerCalculei } from "./servicos/instrumentacao/error.handler";
import { AdminModule } from './admin/admin.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BeneficioPipe } from "./aplicacao/compartilhado/pipes/beneficiopipe";
import { MeiosPagamento } from "./modelos/pagamento/meiospagamento";
import { MeiosPagamentoPipe } from "./aplicacao/compartilhado/pipes/meiospagamentopipe";
import { PeriodoPipe } from "./aplicacao/compartilhado/pipes/periodoplanopipe";


import { AccordionModule } from 'ngx-bootstrap/accordion';



import { TipoAtividadePipe } from "./aplicacao/compartilhado/pipes/tipoatividadepipe";


import { NgxSliderModule } from 'ngx-slider-v2';

import { MarcadorPipe } from "./aplicacao/compartilhado/pipes/marcadorpipe";


import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TipoIndicadorPipe } from "./aplicacao/compartilhado/pipes/tipo-indicador.pipe";
import { GrupoIndicadorPipe } from "./aplicacao/compartilhado/pipes/grupo-indicador.pipe";
import { MoedaCompetenciaPipe } from "./aplicacao/compartilhado/pipes/moeda-competencia.pipe";
import { TipoContribuicaoPipe } from "./aplicacao/compartilhado/pipes/tipo-contribuicao.pipe";
import { PosteriorPlanoRealPipe } from "./aplicacao/compartilhado/pipes/posterior-plano-real.pipe";
import { TipoBeneficioLiquidacaoPipe } from "./aplicacao/compartilhado/pipes/tipo-beneficio-liquidacao.pipe";
import { TipoProcessoLiquidacaoPipe } from "./aplicacao/compartilhado/pipes/tipo-processo-liquidacao.pipe";
import { CheckDataAtualLimitePipe } from "./aplicacao/compartilhado/pipes/check-data-atual-limite.pipe";
import { TipoVinculoPipe } from "./aplicacao/compartilhado/pipes/tipo-vinculo.pipe";
import { ProtocolosCorrecaoPipe } from "./aplicacao/compartilhado/pipes/protocolos-correcao.pipe";
import { ProtocolosJurosPipe } from "./aplicacao/compartilhado/pipes/protocolos-juros.pipe";
import { StatusAssinaturaPipe } from "./aplicacao/compartilhado/pipes/status-assinatura.pipe";
import { FiltroCasosMatrizPipe } from "./aplicacao/compartilhado/pipes/filtro-casos-matriz.pipe";
import { TipoRespostaPipe } from "./aplicacao/compartilhado/pipes/tipo-resposta.pipe";
import { SalaEntrevistasModule } from './sala-entrevistas/sala-entrevistas.module';

import { ClipboardModule } from '@angular/cdk/clipboard'

// import { ColumnChooserService, DetailRowService, ExcelExportService, FilterService, GridModule, GroupService, Resize, ResizeService, SelectionService, SortService, ToolbarService, VirtualScrollService } from '@syncfusion/ej2-angular-grids';
import { MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';


registerLocaleData(localePt, 'pt');


import { TintaFundoPipe } from "./aplicacao/compartilhado/pipes/tinta-fundo.pipe";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import { CompararCenariosPipe } from "./aplicacao/compartilhado/pipes/comparar-cenarios.pipe";

import { GanttModule, GanttComponent } from '@syncfusion/ej2-angular-gantt';
import { EditService, FilterService as FilterServiceGantt, SortService as SortServiceGantt, SelectionService as SelectionServiceGantt, ToolbarService as ToolbarServiceGantt, DayMarkersService } from '@syncfusion/ej2-angular-gantt';
import { LogService } from "./servicos/log.service";
import { AnonimoPipe } from "./aplicacao/compartilhado/pipes/anonimopipe";
import { IndicadoresUnicosPipe } from "./aplicacao/compartilhado/pipes/indicadores-unicos.pipe";
import { HeatMapAllModule, HeatMapModule, LegendService, TooltipService } from "@syncfusion/ej2-angular-heatmap";
import { ToastModule } from "@syncfusion/ej2-angular-notifications";
import { SliderModule } from '@syncfusion/ej2-angular-inputs'
import { TotalDiasSequenciaPipe } from "./aplicacao/compartilhado/pipes/total-dias-sequencia.pipe";
import { FrequenciaPipe } from "./aplicacao/compartilhado/pipes/frequencia.pipe";







@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    BlogLayoutComponent,

  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AplicacaoModule,
    ServicosModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://calculeiapi.azurewebsites.net', 'https://calculeiappapi.azurewebsites.net', 'https://calculeiappapilx.azurewebsites.net', 'http://localhost', 'https://calculeiservices.azurewebsites.net'],
        sendAccessToken: true,
        // referencia para azure function autenticada com b2c
        // https://medium.com/@ravindraa/secure-azure-functions-using-azure-ad-b2c-986e4ad07c6c
      }
    }),
    PaginasModule,
    FontAwesomeModule,
    UiSwitchModule,
    ButtonsModule.forRoot(),
    InputMaskModule.forRoot({ inputSelector: 'input', isAsync: true }),
    MarkdownModule.forRoot({ loader: HttpClient, sanitize: SecurityContext.NONE }),
    NgxSliderModule,
    TabsModule.forRoot(),
    NgbModule,
    RelatoriosModule,
    SweetAlert2Module.forRoot(),
    AdminModule,
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    SalaEntrevistasModule,
    ClipboardModule,
    // GridModule,
    MaskedTextBoxModule,
    DatePickerModule,

    HeatMapModule,
    HeatMapAllModule,
    ToastModule,
    SliderModule




  ],
  providers: [
    DatePipe,
    DecimalPipe,
    BeneficioPipe,
    MarcadorPipe,
    TipoAtividadePipe,
    MoedaCompetenciaPipe,
    TipoContribuicaoPipe,
    PosteriorPlanoRealPipe,
    PeriodoPipe,
    MeiosPagamentoPipe,
    TipoIndicadorPipe,
    GrupoIndicadorPipe,
    CurrencyPipe,
    TipoBeneficioLiquidacaoPipe,
    TipoProcessoLiquidacaoPipe,
    CheckDataAtualLimitePipe,
    TipoVinculoPipe,
    ProtocolosCorrecaoPipe,
    ProtocolosJurosPipe,
    StatusAssinaturaPipe,
    FiltroCasosMatrizPipe,
    TipoRespostaPipe,
    TintaFundoPipe,
    CompararCenariosPipe,
    GanttModule,

    IndicadoresUnicosPipe,
    // ResizeService,
    // VirtualScrollService,
    // GroupService,
    // ExcelExportService,
    // ToolbarService,
    // SortService,
    // ColumnChooserService,
    // FilterService,
    // SelectionService,
    FilterServiceGantt,
    SortServiceGantt,
    SelectionServiceGantt,
    ToolbarServiceGantt,
    DayMarkersService,
    EditService,
    DayMarkersService,
    LegendService,
    TooltipService,
    TotalDiasSequenciaPipe,
    
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },

    FrequenciaPipe,

    /* if you don't provide the currency symbol in the pipe, 
    this is going to be the default symbol (R$) ... */
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    TabsetConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    },
    {
      provide: ErrorHandler, useClass: ErrorHandlerCalculei
    },
    provideNgxMask(),
    LogService,
    AnonimoPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
