import { Base } from "../base";
import { Atividade } from "./atividade";
import { CamposMatriz } from "./camposmatriz";
import { FiltrosMatriz } from "./filtrosmatriz";
import { OpcoesNumeroContribuicoesPlanejamento } from "./opcoesnumerocontribuicoesplanejamento";
import { OpcoesPlanejamento } from "./opcoesplanejamento";

export class OpcoesMatriz extends Base{
    opcoesPlanejamento:OpcoesPlanejamento[];
    frequencias:OpcoesNumeroContribuicoesPlanejamento[];
    atividades:Atividade[];
    filtros:FiltrosMatriz;
    campos:CamposMatriz;
    otimizacaoKertzman:boolean;
    aplicarCustoImpostoRenda:boolean;
    exibirMarcadores:boolean;
    
    listaRentabilidades:number[];
    listaSalarios:number[];
    listaAliquotas:number[];
    
}