<h5 class=" h3 mb-0 linha-quadro">Contas</h5>
<app-aguarde [visivel]="aguardeVisivel"></app-aguarde>
<div [className]="!aguardeVisivel ? 'visible': 'invisible'" class="dataTables_wrapper py-6">
  <div class="row">

    <div class="col-sm-8 col-md-8 col-lg-8">
      <div class="dataTables_length" id="datatable_length">
        <div class="row py-4">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <label>
              Buscar Cliente por nome, data ou CPF
              <div class="col-lg-4">
                <input type="search" class="form-control" placeholder="Digite o termo da busca"
                  aria-controls="datatable" (keyup)="filterTable($event)" />
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ngx-datatable class="material striped selection-cell" [columnMode]="'force'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries : undefined" [rows]="temp"
    (activate)="onActivate($event)" [messages]="{emptyMessage: 'Nenhum cliente encontrado'}">

    <ngx-datatable-column name="Nome" prop="nome"></ngx-datatable-column>
    <ngx-datatable-column name="E-mail" prop="email"></ngx-datatable-column>
    <ngx-datatable-column name="Data do último Acesso" prop="dataUltimoAcesso">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span>{{ value | date : "dd/MM/yyyy" }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Total de Acessos" prop="totalAcessos"></ngx-datatable-column>

    <ngx-datatable-column name="Data de criação da Conta" prop="dataConta">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span>{{ value | date : "dd/MM/yyyy" }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="E-mail Confirmado" prop="emailConfirmado"></ngx-datatable-column>

  </ngx-datatable>

  <ng-container *ngIf="usuario">
    <div class="row">
      <div class="col-3">
        <p class="mb-0">
          Email
        </p>
        <p>
          {{usuario.email}}
        </p>
      </div>
      <div class="col-3">
        <p class="mb-0">
          Fim periodo teste
        </p>
        <p>
          <!-- {{usuario.fimPeriodoTeste | date : 'dd/MM/yyyy'}} -->
          <input id="editorDataFimPeriodoTeste" [inputMask]="dateInputMask" class=" form-control text-left" type="text" [value]="usuario.fimPeriodoTeste | date : 'dd/MM/yyyy'"  (change)="changeDataFimPeriodoTeste($event,usuario)" />
        </p>
      </div>
    </div>
  </ng-container>
</div>