import { Base } from "../base";
import { PadraoMonetario } from "../common/padraomonetario";
import { ContagemCarencia } from "./contagemcarencia";
import { IdentificadorIndice } from "./identificadorindice";
import { IndicadorCNIS } from "./indicadorcnis";
import { Observacao } from "./observacao";
import { TipoContribuicao } from "./tipocontribuicao";
import { TiposVinculos } from "./tiposvinculos";

export class Contribuicao extends Base{

    tipo:TipoContribuicao;
    competencia:Date;
    dataPagamento:Date;
    valorOriginal:number;
    valor:number;
    indicadores:string[];
    indicadoresCnis:IndicadorCNIS[];
    valorAtualizado:number;
    acumuladoCompetenciaOrigem:number;
    acumuladoCompetenciaAtual:number;
    fatorMultiplicadorCorrecao:number;
    indice:IdentificadorIndice;
    relevante:boolean;
    percentualCorrespondenteIndiceCorrecaoPeriodo:number;
    padraoMonetario:PadraoMonetario;
    valorContribuicao:number;
    descarte:boolean;
    substituicaoTeto:boolean;
    planejamento:boolean;
    otimizada:boolean;
    otimizacao:boolean;
    qualidadeSegurado:boolean;
    
    salarioMinimoCompetencia:number;
    tetoCompetencia:number;
    contagemCarencia:ContagemCarencia;
    menorSalarioMinimo:number;
    carencia:boolean;

    vinculoRowKey:string;
    vinculoSequencia:number;
    contribuicoesConcomitantes:Contribuicao[];


    validaContagemTempoContribuicaoAposentadoriaIdade:boolean;
    validaContagemTempoContribuicaoAposentadoriaTempo:boolean;
    validaContagemCarenciaAposentadoriaIdade:boolean;
    validaContagemCarenciaAposentadoriaTempo:boolean;

    observacoes:Observacao[];

    ignorarValidacao:boolean;


    inicioCompetencia:Date;
    fimCompetencia:Date;
    inicioManutencaoQualidadeSegurado:Date;
    fimManutencaoQualidadeSegurado:Date;
    tipoVinculo:TiposVinculos;
    pagamentoAtrasado:boolean;

    aliquota:number;

    libero:boolean;
    transferida:boolean;
    valorRestituicao:number;
    valorRestituicaoAtualizado:number;
    fatorAtualizacaoRestituicao:number;
    excedenteTeto:number;

    concomitante:boolean;
    totalDiasTempoContribuicaoIdade:number;
    totalDiasTempoContribuicaoTempo:number;

}                   