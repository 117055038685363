

<!--scroll tabela https://stackoverflow.com/questions/63666463/table-width-100-with-overflow-scroll -->
<div [ngClass]="carregando  ? 'visible': 'invisible' ">

  <!-- <app-aguarde-config [aguarde]="carregando"></app-aguarde-config> -->
</div>

<div *ngIf="calculoMatrizPlanejamento && calculoMatrizPlanejamento.casos && calculoMatrizPlanejamento.casos.length > 0 ">

  <!-- <app-destaque-beneficio *ngIf="destaque" [destaque]="destaque"
      (relatorioCompletoEvent)="verRelatorioCompleto($event)"></app-destaque-beneficio> -->

  <div class="pb-4">
    <app-destaque-melhor-cenario *ngIf="calculoMatrizPlanejamento && calculoMatrizPlanejamento.casos" [calculoMatrizPlanejamento]="calculoMatrizPlanejamento" (dastaqueCanvasEvent)="abrirCasoCanvas($event)"></app-destaque-melhor-cenario>

  </div>

  <div class="pb-4">
    <div class="row ">
      <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">
          Todos os Cenários
          <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpCenarios" aria-expanded="false" aria-controls="helpCenarios"></i>
        </h5>

        <div class="collapse " id="helpCenarios">
          <div class="row d-flex ">
            <div class="col">
              <p>
                Tabela de detalhamento de cenários
              </p>
            </div>

          </div>

          <div class="row d-flex ">

            <div class="col-3 py-2 d-flex">
              <div class="row bg-secondary rounded m-2 w-100">
                <div class="col d-flex p-2">

                  <p class=" p-2 lh-1 text-justify">
                    Utilize os botões para modificar a visualização de cenários. Clique nos valores, para fazer anotações.
                  </p>

                </div>
                <div class="mt-auto pb-2 text-right">
                  <!-- <i class="comando bi bi-plus-square-dotted fs-3"></i> -->
                </div>

              </div>

            </div>

            <div class="col-3 py-2 d-flex">
              <div class="row bg-secondary rounded m-2 w-100">
                <div class="col d-flex p-2">

                  <p class=" p-2 lh-1 text-justify">

                    Filtrar os cenários por regra, alíquota ou salário de contribuição

                  </p>

                </div>
                <div class="mt-auto pb-2 text-right">
                  <i class="comando bi bi-funnel fs-3"></i>
                </div>

              </div>

            </div>

            <div class="col-3 py-2 d-flex">
              <div class="row bg-secondary rounded m-2 w-100">
                <div class="col d-flex p-2">

                  <p class=" p-2 lh-1 text-justify">

                    Mostrar ou esconder as tags

                  </p>

                </div>
                <div class="mt-auto pb-2 text-right">
                  <i class="comando bi bi-bookmarks fs-3"></i>

                </div>

              </div>

            </div>

            <div class="col-3 py-2 d-flex">
              <div class="row bg-secondary rounded m-2 w-100">
                <div class="col d-flex p-2">

                  <p class=" p-2 lh-1 text-justify">

                    Filtrar campos. Visualize apenas as informações mais relevantes de cada cenário na tabela
                  </p>

                </div>
                <div class="mt-auto pb-2 text-right">
                  <i class="comando bi bi-filter-square fs-3"></i>
                </div>

              </div>

            </div>

            <div class="col-3 py-2 d-flex">
              <div class="row bg-secondary rounded m-2 w-100">
                <div class="col d-flex p-2">

                  <p class=" p-2 lh-1 text-justify">

                    Ordenar os cenários por ordem alfabética

                  </p>

                </div>
                <div class="mt-auto pb-2 text-right">
                  <i class="comando bi bi-sort-alpha-up fs-3"></i>

                </div>

              </div>

            </div>

            <div class="col-3 py-2 d-flex">
              <div class="row bg-secondary rounded m-2 w-100">
                <div class="col d-flex p-2">

                  <p class=" p-2 lh-1 text-justify">

                    Ordenar os cenários por valor do benefício

                  </p>

                </div>
                <div class="mt-auto pb-2 text-right">
                  <i class="comando bi bi-sort-numeric-up fs-3"></i>
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

    <div class="row py-2  flex d-flex justify-content-between">

      <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
          <a class="text-default" data-bs-toggle="collapse" data-bs-target="#areaFiltros" aria-expanded="false" aria-controls="areaFiltros" role="button" container="body" ngbTooltip="Mostrar filtros de cenários">
            <i class="comando bi bi-funnel fs-6"></i>
            <i class="comando bi bi-funnel-fill fs-6 "></i>

          </a>
        </div>

        <div class="col-auto p-0 m-0">

          <a class="text-default " aria-expanded="false" data-bs-toggle="collapse" role="button" href="#marcadoresExibir" container="body" ngbTooltip="Mostrar marcadores">
            <a class id="marcadoresExibir"></a>
            <i class="comando bi bi-bookmarks fs-6 " (click)="marcadoresExibir(true)"></i>
            <i class="comando bi bi-bookmarks-fill fs-6 " (click)="marcadoresExibir(false)"></i>

          </a>
        </div>

        <div class="col-auto p-0 m-0">
          <a class="text-default " aria-expanded="false" data-bs-toggle="collapse" role="button" href="#areaCampos" container="body" ngbTooltip="Mostrar filtro de campos da tabela">
            <i class="comando bi bi-filter-square fs-6 " (click)="marcadoresExibir(true)"></i>
            <i class="comando bi bi-filter-square-fill fs-6 " (click)="marcadoresExibir(false)"></i>

          </a>

        </div>

        <div class="col-auto p-0 m-0">
          <a class="text-default" aria-expanded="false" data-bs-toggle="collapse" role="button" href="#alphaFiltro" container="body" ngbTooltip="Ordem alfabética por nome dos cenários">
            <a class id="alphaFiltro"></a>
            <i class="comando bi bi-sort-alpha-down fs-6 " (click)="ordenarAlpha(true)"></i>
            <i class="comando bi bi-sort-alpha-up fs-6 " (click)="ordenarAlpha(false)"></i>

          </a>

        </div>

        <div class="col-auto p-0 m-0">
          <a class="text-default" aria-expanded="false" data-bs-toggle="collapse" role="button" href="#valorBeneficioFiltro" container="body" ngbTooltip="Ordenar por valor de benefício">
            <a class id="valorBeneficioFiltro"></a>
            <i class="comando bi bi-sort-numeric-down fs-6 " (click)="ordernarValor(true)"></i>
            <i class="comando bi bi-sort-numeric-up fs-6 " (click)="ordernarValor(false)"></i>

          </a>
        </div>

      </div>

      <!-- <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
          <a class="text-default " role="button" (click)="pdfMatriz()" container="body" ngbTooltip="Exportar tabela em PDF">
            <i class="comando bi bi-filetype-pdf fs-6 "></i>
          </a>
        </div>
  
        <div class="col-auto p-0 m-0">
          <a class="text-default " role="button" container="body" ngbTooltip="Exportar tabela em excel">
            <i class="comando bi bi-filetype-xls fs-6 "></i>
          </a>
        </div>
      </div> -->

    </div>
    <!-- campos -->
    <div class="collapse" id="areaCampos">
      <div class="row ">
        <div class="col-12">
          <p class="h3">
            Mostrar Campos
          </p>
        </div>
      </div>

      <div class="row m-0 p-0  flex d-flex">
        <div class="col-auto p-0 m-0">
          <a class="text-default " role="button" (click)="marcarTodosCampos()" container="body" ngbTooltip="Marcar todos">
            <i class="comando bi bi-ui-checks fs-6 "></i>
          </a>
        </div>

        <div class="col-auto p-0 m-0">
          <a class="text-default " role="button" (click)="limparTodosCampos()" container="body" ngbTooltip="Limpar todos">
            <i class="comando bi bi-grid fs-6 "></i>
          </a>
        </div>
      </div>
      <form [formGroup]="camposForm">
        <!-- campos -->
        <div class="row p-1">

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoAliquotaSalario">
              <label class="form-check-label">
                Alíquota / Salário
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoDataAposentadoria">
              <label class="form-check-label">
                Data da Aposentadoria
              </label>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoRegra">
              <label class="form-check-label">
                Regra
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoValorBeneficio">
              <label class="form-check-label">
                Valor do Benefício
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoTotalContribuicoes">
              <label class="form-check-label">
                Total de Contribuições
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoValorContribuicao">
              <label class="form-check-label">
                Valor da Contribuição
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoTotalInvestimento">
              <label class="form-check-label">
                Total do Investimento
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoRoiAcumulado">
              <label class="form-check-label">
                ROI Acumulado
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoTaxaRetorno">
              <label class="form-check-label">
                Taxa de Retorno
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoGanhoRoi">
              <label class="form-check-label">
                Ganho no ROI
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="campoPercentualTeto">
              <label class="form-check-label">
                Percentual do Teto do INSS
              </label>
            </div>
          </div>

        </div>
      </form>
    </div>

    <!-- filtros -->
    <div class="collapse" id="areaFiltros">
      <div class="row ">
        <div class="col-12">
          <p class="h3">
            Filtros
          </p>
        </div>
      </div>

      <div class="row m-0 p-0  flex d-flex">
        <div class="col-auto p-0 m-0">
          <a class="text-default " role="button" (click)="marcarTodosFiltros()" container="body" ngbTooltip="Marcar todos">
            <i class="comando bi bi-ui-checks fs-6 "></i>
          </a>
        </div>

        <div class="col-auto p-0 m-0">
          <a class="text-default " role="button" (click)="limparTodosFiltros()" container="body" ngbTooltip="Limpar todos">
            <i class="comando bi bi-grid fs-6 "></i>
          </a>
        </div>
      </div>

      <form [formGroup]="filtrosForm">
        <!-- regras -->
        <div class="row">
          <div class="col-1">
            <p class="h3">
              Regras:
            </p>
          </div>
          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroRegraIdadeTransicao">
              <label class="form-check-label">
                Idade Transição
              </label>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroRegraPedagio100">
              <label class="form-check-label">
                Pedágio 100%
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroRegraPedagio50">
              <label class="form-check-label">
                Pedágio 50%
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroRegraIdadeMinimaProgressiva">
              <label class="form-check-label">
                Idade Mínima Progressiva
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroRegraPontos">
              <label class="form-check-label">
                Pontos
              </label>
            </div>
          </div>
        </div>

        <!-- aliquota -->
        <div class="row ">
          <div class="col-1">
            <p class="h3">
              Alíquota:
            </p>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroAliquota11">
              <label class="form-check-label">
                11%
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroAliquota20">
              <label class="form-check-label">
                20%
              </label>
            </div>
          </div>

        </div>
        <!-- salario -->
        <div class="row">
          <div class="col-1">
            <p class="h3">
              Salário:
            </p>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroSalarioMinimo">
              <label class="form-check-label">
                Salário Mïnimo
              </label>
            </div>
          </div>

          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="filtroSalarioTeto">
              <label class="form-check-label">
                Teto
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="pb-4">
    <app-tabela-matriz *ngIf="calculoMatrizPlanejamento" [calculoMatrizPlanejamento]="calculoMatrizPlanejamento" (atualizacaoMatrizEvent)="atualizarMatriz($event)"></app-tabela-matriz>
  </div>

  <div class="pb-4">
    <app-heat-map-matriz *ngIf="calculoMatrizPlanejamento && calculoMatrizPlanejamento.casos" [calculoMatrizPlanejamento]="calculoMatrizPlanejamento" (cenarioCanvasEvent)="abrirCasoCanvas($event)"></app-heat-map-matriz>
  </div>

</div>

<!-- <div class="py-4">
  <app-tabela-custo-oportunidade *ngIf="calculoMatrizPlanejamento && calculoMatrizPlanejamento.matrizCustoOportunidade" [calculoMatrizPlanejamento]="calculoMatrizPlanejamento"></app-tabela-custo-oportunidade>
</div> -->

<div class="offcanvas h-100 offcanvas-bottom" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasCaso" aria-labelledby="canvasCaso">

  <div class="offcanvas-header ml-6 mr-2 justify-content-between">
    <div *ngIf="casoCanvas" class="w-100 mr-4">
      <div class="row d-flex">
        <div class="col-auto " *ngIf="casoCanvas && casoCanvas.beneficio">
          <span class="h1 lh-1 fw-lighter"> {{casoCanvas.beneficio.qualificacaoDetalhada}}</span>

        </div>
        <div class="col-auto p-0">

          <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpSequencia" aria-expanded="false" aria-controls="helpSequencia"></i>

        </div>
      </div>

      <div class="collapse" id="helpSequencia">
        <p class="lh-1 py-2">
          Detalhamento da simulação
        </p>

      </div>
    </div>
    <!-- <span *ngIf="sequencia" class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Sequência {{sequencia.sequencia}}</span> -->
    <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
    <div class="row flex d-flex align-items-top justify-content-end">

      <div class="hstack">
        <!-- <div [className]="aguarde ? 'visible': 'invisible'">
            <div class="mx-4">
              <app-aguarde-local [aguarde]="aguarde"></app-aguarde-local>
            </div>
          </div> -->
        <div class>
          <i role="button" class="comando bi bi-filetype-pdf fs-6" (click)="gerarPDFCaso(casoCanvas)" container="body" ngbTooltip="PDF"></i>

        </div>

        <div class>
          <i role="button" class="comando bi bi-x fs-6" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar" (click)="fecharCanvasCaso()"></i>

        </div>
      </div>

    </div>

  </div>
  <div class="offcanvas-body ml-6 mr-2">

    <div class="py-2">
      <!-- <app-relatorio-beneficio #relatorioBeneficio *ngIf="casoCanvas" [beneficio]="casoCanvas.beneficio"></app-relatorio-beneficio> -->
    </div>
    <div class="py-2">
      <app-relatorio-caso-planejamento #relatorioCaso *ngIf="casoCanvas" [caso]="casoCanvas"></app-relatorio-caso-planejamento>
    </div>

  </div>

</div>

<div class="offcanvas h-100 offcanvas-bottom" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasKertzman" aria-labelledby="canvasKertzman">

  <div class="offcanvas-header ml-6 mr-2 justify-content-between">
    <div *ngIf="kertzman" class="w-100 mr-4">
      <div class="row d-flex">
        <div class="col-auto " *ngIf="kertzman">
          <span class="h1 lh-1 fw-lighter"> {{kertzman.relatorio.otimizacao.beneficio.qualificacaoDetalhada}}</span>

        </div>
        <div class="col-auto p-0">

          <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpCanvasKertzman" aria-expanded="false" aria-controls="helpCanvasKertzman"></i>

        </div>
      </div>

      <div class="collapse" id="helpCanvasKertzman">
        <p class="lh-1 py-2">
          Detalhamento da Otimização
        </p>

      </div>
    </div>
    <!-- <span *ngIf="sequencia" class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Sequência {{sequencia.sequencia}}</span> -->
    <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
    <div class="row flex d-flex align-items-top justify-content-end">

      <div class="hstack">
        <!-- <div [className]="aguarde ? 'visible': 'invisible'">
            <div class="mx-4">
              <app-aguarde-local [aguarde]="aguarde"></app-aguarde-local>
            </div>
          </div> -->
        <div class>
          <i role="button" class="comando bi bi-filetype-pdf fs-6" (click)="gerarPDFKertzman(kertzman)" container="body" ngbTooltip="PDF"></i>

        </div>

        <div class>
          <i role="button" class="comando bi bi-x fs-6" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar" (click)="fecharCanvasKertzman()"></i>

        </div>
      </div>

    </div>

  </div>
  <div class="offcanvas-body ml-6 mr-2">

    <div class="py-2">
      <app-sumario-resultado-planejamento-kertzman #sumarioResultado
        *ngIf="kertzman && kertzman.relatorio"
        [calculoPlanejamentoKertzman]="kertzman"
        (otimizacaoEspecificaEvent)="atualizarOtimizacaoEspecifica($event)">
      </app-sumario-resultado-planejamento-kertzman>
    </div>
  </div>

</div>