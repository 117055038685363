<div class="row">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">Sequências do CNIS
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroSequencias" aria-expanded="false" aria-controls="helpQuadroSequencias"></i>
        </h5>

        <div class="collapse" id="helpQuadroSequencias">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 text-justify">
                                Faça os ajustes necessários nas sequências, para que o cálculo seja consistente.
                                Para incluir novas sequências clique em:
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-plus-square-dotted fs-3"></i>
                        </div>

                    </div>

                </div>

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 text-justify">
                                No painel Planilha, você tem todas as contribuições na forma como foram importadas do CNIS, e depois do processamento e atualização.
                                Trazemos dados associados que facilitam o trabalho pré cálculo.
                                Ideal para quem gosta de trabalhar em Excel. Clique em:

                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-file-spreadsheet fs-3"></i>
                        </div>

                    </div>

                </div>

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">
                            <p class="p-2  text-justify">
                                Em Validação de Tempo e Carência, apresentamos um checklist de validação de cada sequência/contribuição.
                                Aqui você tem um resumo da contagem de tempo e o passo a passo da contagem. Para abrir o painel de contagem de tempo de contribuição clique no botão:
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-calendar2-check fs-3"></i>
                        </div>

                    </div>

                </div>

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">
                            <p class="p-2  text-justify">
                                No painel Indicadores, trazemos, para cada indicador presente no CNIS, as informações do indicador no Anexo IV DIRBEN. Quadno aplicável, trazemos as providências práticas para solucionar as pendências informadas pelo indicador.
                            </p>
                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-exclamation-lg fs-3"></i>

                        </div>

                    </div>

                </div>

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">
                            <p class="p-2  text-justify">
                                O painel Pontos de Atenção traz uma série de alertas que identificamos durante a análise do CNIS.
                                Os pontos de atenção servem como aviso de que as inconsistências do CNIS podem sugerir uma contagem incorreta de tempo de contribuição e carência.
                                Para visualizar os pontos de atenção clique em:
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-stoplights fs-3"></i>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div class="row pt-4  flex d-flex justify-content-between">

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">

            <a class="text-default" data-bs-toggle="collapse" data-bs-target="#novaSequencia" aria-expanded="false" aria-controls="novaSequencia" role="button">
                <i class="comando bi bi-plus-square-dotted fs-6" container="body" ngbTooltip="Criar nova sequência" triggers="hover"></i>
                <i class="comando bi bi-plus-square-fill fs-6"></i>
            </a>

            <!-- <app-nova-relacao-previdenciaria (adcionarVinculoFinalizadoEvent)="adicionarVinculoFinalizado($event)"></app-nova-relacao-previdenciaria> -->
            <i class="comando bi bi-file-spreadsheet fs-6 " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasPlanilhaContribuicoes" aria-controls="canvasPlanilhaContribuicoes" (click)="abrirPlanilhas()" container="body" ngbTooltip="Planilha de contribuições" triggers="hover"></i>
            <!-- <i class="comando bi bi-stopwatch fs-6 " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasTempoContribuicao" aria-controls="canvasTempoContribuicao" container="body" ngbTooltip="Tempo de Contribuição" triggers="hover"></i> -->
            <i class="comando bi bi-calendar2-check fs-6 " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasValidacao" aria-controls="canvasValidacao" (click)="abrirValidacao()" container="body" ngbTooltip="Validação" triggers="hover"></i>
            <i class="comando bi bi-exclamation-lg fs-6 " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasIndicadores" aria-controls="canvasIndicadores" (click)="abrirIndicadores()" container="body" ngbTooltip="Indicadores" triggers="hover"></i>
            <i class="comando bi bi-stoplights fs-6 " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasPontosAtencao" aria-controls="canvasPontosAtencao" (click)="abrirPontosAtencao()" container="body" ngbTooltip="Pontos de Atenção" triggers="hover"></i>

            <!-- <a class="text-default " aria-expanded="false" data-bs-toggle="collapse" role="button" href="#marcadoresExibir">
                <a class id="marcadoresExibir"></a>
                <i class="comando bi bi-check-circle fs-6 " (click)="sequenciasProcessadas(true)" container="body" ngbTooltip="Mostrar sequências validadas" triggers="hover"></i>
                <i class="comando bi bi-check-circle-fill fs-6 " (click)="sequenciasProcessadas(false)" container="body" ngbTooltip="Mostrar sequências originais" triggers="hover"></i>

            </a> -->
        </div>

    </div>

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
            <span class="comando fs-6 " role="button" (click)="todosEspecial(15)" container="body" ngbTooltip="Especial 15 para todos" triggers="hover">E15</span>
            <span class="comando fs-6 " role="button" (click)="todosEspecial(20)" container="body" ngbTooltip="Especial 20 para todos" triggers="hover">E20</span>
            <span class="comando fs-6 " role="button" (click)="todosEspecial(25)" container="body" ngbTooltip="Especial 25 para todos" triggers="hover">E25</span>
            <!-- <a class="text-default " aria-expanded="false" data-bs-toggle="collapse" role="button" href="#telaCheia">
                <a class id="telaCheia"></a>
                <i class="comando bi bi-fullscreen fs-6 " role="button" (click)="maximizarGridSequencias(true)" container="body" ngbTooltip="Tela cheia" triggers="hover"></i>
                <i class="comando bi bi-fullscreen-exit fs-6 " role="button" (click)="maximizarGridSequencias(false)" container="body" ngbTooltip="Exportar Excel" triggers="hover"></i>

            </a>
             -->
        </div>
        <div class="col-auto p-0 m-0">
            <!-- <i class="comando bi bi-filetype-xls fs-6 " role="button" (click)="exportarXls()" container="body" ngbTooltip="Exportar Excel" triggers="hover"></i> -->
        </div>

        <div class="col-auto p-0 m-0">
            <!-- <i class="comando bi bi-filetype-csv fs-6 " role="button" (click)="exportarCsv()" container="body" ngbTooltip="Exportar CSV" triggers="hover"></i> -->
        </div>
    </div>

</div>

<div class="collapse" id="novaSequencia">
    <div class="bg-secondary rounded p-2">

        <div class="row py-2">
            <div class="col">
                <span class="h3">Nova Sequência</span>
            </div>
        </div>
        <div class="row py-2">

            <div class="col-3">
                <span class="d-block">Data início</span>
                <div style="min-width: 150px;">
                    <ejs-datepicker [enableMask]="true" [strictMode]="true" (change)="changeDataInicioNovaSequencia($event)" locale="pt" [(ngModel)]="novaSequencia.dataInicio"></ejs-datepicker>
                </div>
            </div>
            <div class="col-3">
                <span>Data fim</span>
                <div style="min-width: 150px;">
                    <ejs-datepicker [enableMask]="true" [strictMode]="true" (change)="changeDataFimNovaSequencia($event)" locale="pt" [(ngModel)]="novaSequencia.dataFim"></ejs-datepicker>
                </div>
            </div>

        </div>
        <div class="hstack gap-3 py-2 d-flex align-items-end">
            <div class="col-3 m--2 p-2">
                <span class="d-block">Nome da sequência</span>
                <input tabindex="-1" type="text" class="form-control" id="nomeNovaSequencia" placeholder="Nova sequência" [(ngModel)]="novaSequencia.origemVinculo">

            </div>
            <div>
                <span class="d-block">Tipo do Vínculo</span>
                <div class="dropdown">
                    <button class="btn btn-outline-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{novaSequencia.tipoVinculo | tipoVinculo}}
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.SOCIOEMPRESARIO)">{{tiposVinculosType.EMPREGADOOUAGENTEPUBLICO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.EMPREGADOOUAGENTEPUBLICO)">{{tiposVinculosType.EMPREGADOOUAGENTEPUBLICO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.CONTRIBUINTEINDIVIDUAL)">{{tiposVinculosType.CONTRIBUINTEINDIVIDUAL| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.BENEFICIO)">{{tiposVinculosType.BENEFICIO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.FACULTATIVO)">{{tiposVinculosType.FACULTATIVO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.EMPREGADODOMESTICO)">{{tiposVinculosType.EMPREGADODOMESTICO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.TRABALHADORAVULSO)">{{tiposVinculosType.TRABALHADORAVULSO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.SEGURADOESPECIAL)">{{tiposVinculosType.SEGURADOESPECIAL| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.SEGURADOFACULTATIVO)">{{tiposVinculosType.SEGURADOFACULTATIVO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.CONTRIBUINTEEMDOBRO)">{{tiposVinculosType.CONTRIBUINTEEMDOBRO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.AUTONOMO)">{{tiposVinculosType.AUTONOMO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.PENSAOMORTE)">{{tiposVinculosType.PENSAOMORTE| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.MEI)">{{tiposVinculosType.MEI| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.PRESTADORSERVICO)">{{tiposVinculosType.PRESTADORSERVICO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.AUXILIOINCAPACIDADETEMPORARIA)">{{tiposVinculosType.AUXILIOINCAPACIDADETEMPORARIA| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.AUXILIOINCAPACIDADEPERMANENTE)">{{tiposVinculosType.AUXILIOINCAPACIDADEPERMANENTE| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoFiliacaoVinculoNovaSequencia(tiposVinculosType.SALARIOMATERNIDADE)">{{tiposVinculosType.SALARIOMATERNIDADE| tipoVinculo}}</button></li>

                    </ul>
                </div>

            </div>

            <div>
                <span class="d-block">Tipo de atividade</span>
                <div class="dropdown">
                    <button class="btn btn-outline-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{novaSequencia.tipoAtividade | tipoAtividade}}
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" (click)="editarTipoAtividadeNovaSequencia(tiposAtividadeType.Normal)">Normal</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividadeNovaSequencia(tiposAtividadeType.Professor)">Professor</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividadeNovaSequencia(tiposAtividadeType.Rural)">Rural</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividadeNovaSequencia(tiposAtividadeType.Especial15)">Especial 15</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividadeNovaSequencia(tiposAtividadeType.Especial20)">Especial 20</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividadeNovaSequencia(tiposAtividadeType.Especial25)">Especial 25</button></li>
                    </ul>
                </div>

            </div>

            <div>
                <span class="d-block">Salário de contribuição</span>
                <div class="dropdown">
                    <button class="btn btn-outline-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{salarioContribuicaoInicialNovaSequencia}}
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" (click)="editarSalarioContribuicaoInicialNovaSequencia('minimo')">Salário mínimo</button></li>
                        <li><button class="dropdown-item" (click)="editarSalarioContribuicaoInicialNovaSequencia('teto')">Teto do INSS</button></li>

                    </ul>
                </div>
            </div>

            <div class="align-items-end">

                <i class="comando bi bi-arrow-right fs-6 " role="button" (click)="confirmarNovaSequencia()" container="body" ngbTooltip="Criar sequência" triggers="hover"></i>
            </div>

        </div>
        <div class="row align-items-end py-2">

        </div>
    </div>

</div>

<div class="row py-2">
    <div class="col">
        <div class="table-responsive">
            <table class="table table-sm  table-striped  table-hover align-middle ">
                <thead>

                </thead>
                <tbody>

                    <tr>
                        <th scope="col" class=" text-center align-middle">
                            <span>
                                Seq.
                            </span>
                        </th>

                        <th scope="col " class=" origem text-center align-middle">
                            <span>
                                Origem
                            </span>
                        </th>
                        <th scope="col" class="text-center align-middle">
                            <span>
                                Data Início
                            </span>
                        </th>
                        <th scope="col" class="text-center align-middle">
                            <span>
                                Data Fim
                            </span>
                        </th>
                        <th scope="col" class="text-center align-middle">
                            <span>
                                Tempo de Contribuição
                            </span>
                        </th>
                        <th scope="col" class=" text-center align-middle">
                            <span>
                                Carência
                            </span>
                        </th>

                        <!-- <th scope="col" class="text-center align-middle">
                            <span>
                                Tipo de Filiação no Vínculo
                            </span>
                        </th> -->
                        <!-- <th scope="col" class="text-center align-middle">
                            <span>
                                Tipo de Atividade
                            </span>
                        </th> -->
                        <!-- <th scope="col">
                            <span>
                                Origem
                            </span>
                        </th> -->
                        <th scope="col" class="text-center align-middle">
                            <span>
                                Opções
                            </span>
                        </th>
                        <!-- <th scope="col">
                            <span>
                                Indicadores
                            </span>
                        </th>
                        <th scope="col">
                            <span>
                                Pontos de Atenção
                            </span>
                        </th> -->
                    </tr>

                    <ng-container class *ngFor="let sequencia of calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias; index as index;">

                        <tr>
                            <td class="align-middle text-center " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasSequencia" aria-controls="canvasSequencia" (click)="abrirSequencia(sequencia)">
                                <span>
                                    {{sequencia.sequencia}}
                                </span>
                                <ng-container *ngIf="sequencia.marcadores && sequencia.marcadores.length > 0">
                                    <ng-container *ngFor="let marcador of sequencia.marcadores">

                                        <ng-container *ngIf="marcador.tipoMarcador == tiposMarcadores.RECORTESEQUENCIA">
                                            <i class="bi bi-scissors destaque-sequencia"></i>
                                        </ng-container>

                                        <ng-container *ngIf="marcador.tipoMarcador == tiposMarcadores.NOVASEQUENCIA">
                                            <i class="bi bi-plus-square-dotted destaque-sequencia"></i>
                                        </ng-container>

                                    </ng-container>

                                </ng-container>

                            </td>
                            <td class="align-middle origem" role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasSequencia" aria-controls="canvasSequencia" (click)="abrirSequencia(sequencia)">
                                <span>
                                    {{sequencia.origemVinculo}}
                                </span>

                            </td>
                            <td class="align-middle  text-center editorData">

                                <div class="vstack ">
                                    <!-- <ejs-datepicker (focus)="datePickerSet($event, sequencia.dataInicio)" [strictMode]="true" (change)="changeDataInicio($event,sequencia, index)" locale="pt" [(ngModel)]="sequencia.dataInicio"></ejs-datepicker> -->
                                     <!-- <input type="date" [(ngModel)]="sequencia.dataInicio"/> -->
                                      <div class="mx-1 py-1">
                                        <app-aguarde-local [id]="'aguardeDataInicioSequencia'+index"></app-aguarde-local>
                                      </div>
                                     <div>
                                        <input  [id]="'editorDataInicioSequencia'+index" [inputMask]="dateInputMask" class=" form-control text-left" type="text" [value]="sequencia.dataInicio | date : 'dd/MM/yyyy'"  (change)="changeDataInicio($event,sequencia,index)" />
                                     </div>
                                </div>
                            </td>
                            <td class="align-middle  text-center editorData">

                                <div class="vstack ">
                                    <!-- <ejs-datepicker (focus)="datePickerSet($event, sequencia.dataInicio)" [strictMode]="true" (change)="changeDataInicio($event,sequencia, index)" locale="pt" [(ngModel)]="sequencia.dataInicio"></ejs-datepicker> -->
                                     <!-- <input type="date" [(ngModel)]="sequencia.dataInicio"/> -->
                                      <div class="mx-1 py-1">
                                        <app-aguarde-local [id]="'aguardeDataFimSequencia'+index"></app-aguarde-local>
                                      </div>
                                     <div>
                                        <input [id]="'editorDataFimSequencia'+index" [inputMask]="dateInputMask" class=" form-control text-left" type="text" [value]="sequencia.dataFim | date : 'dd/MM/yyyy'" (change)="changeDataFim($event,sequencia,index)"/>
                                     </div>
                                </div>

                                <div>
                                    
                                    <!-- <input type="date" [(ngModel)]="sequencia.dataFim" [value]="sequencia.dataFim"/> -->
                                    <!-- <ejs-datepicker (focus)="datePickerSet($event, sequencia.dataFim)" [strictMode]="true" (change)="changeDataFim($event,sequencia, index)" locale="pt" [(ngModel)]="sequencia.dataFim"></ejs-datepicker> -->
                                </div>

                            </td>
                            <td class="text-center align-middle" role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasSequencia" aria-controls="canvasSequencia" (click)="abrirSequencia(sequencia)">

                                <!-- <span class="d-block">{{sequencia.tempoContribuicaoIdade.porExtenso}} | {{sequencia.tempoContribuicaoTempoContribuicao.porExtenso}}</span> -->
                                <!-- <span class="d-block h6">{{sequencia.tempoContribuicaoBruto.porExtenso}}</span> -->

                                <div class="vstack">
                                    <div>
                                        <div *ngIf="sequencia.tempoContribuicaoIdade">
                                            <span class>{{sequencia.tempoContribuicaoIdade.porExtenso}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="sequencia.marcadores && sequencia.marcadores.length > 0">
                                            <ng-container *ngFor="let marcador of sequencia.marcadores">

                                                <div class="vstack">
                                                    <div>
                                                        <ng-container *ngIf="marcador.tipoMarcador == tiposMarcadores.ALERTABASEMINIMO">
                                                            <span class="h6 lh1 fw-lighter text-alerta">
                                                                <i class="bi bi-cone-striped"></i>
                                                                Base Salário Mínimo
                                                            </span>
                                                        </ng-container>
                                                    </div>

                                                    <div>
                                                        <ng-container *ngIf="marcador.tipoMarcador == tiposMarcadores.ALERTAALIQUOTAREDUZIDA">
                                                            <span class="h6 lh1 fw-lighter text-alerta">
                                                                <i class="bi bi-cone-striped"></i>
                                                                Alíquota Reduzida
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                    <div>
                                                        <ng-container *ngIf="marcador.tipoMarcador == tiposMarcadores.ALERTACONCOMITANCIAAJUSTADA">
                                                            <span class="h6 lh1 fw-lighter text-alerta">
                                                                <i class="bi bi-cone-striped"></i>
                                                                Concomitância Ajustada
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                    <div>
                                                        <ng-container *ngIf="marcador.tipoMarcador == tiposMarcadores.ALERTACONCOMITANCIAAJUSTADAREDUZIDA">
                                                            <span class="h6 lh1 fw-lighter text-alerta">
                                                                <i class="bi bi-cone-striped"></i>
                                                                Tempo reduzido por concomitância
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                    <div>
                                                        <ng-container *ngIf="marcador.tipoMarcador == tiposMarcadores.ALERTACONTRIBUICAOIMPLICITA">
                                                            <span class="h6 lh1 fw-lighter text-alerta">
                                                                <i class="bi bi-cone-striped"></i>
                                                                Considera Salário Mínimo
                                                            </span>
                                                        </ng-container>
                                                    </div>

                                                </div>

                                            </ng-container>

                                        </ng-container>

                                    </div>

                                </div>

                            </td>
                            <!-- contribuições -->
                            <td class="align-middle text-center " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasSequencia" aria-controls="canvasSequencia" (click)="abrirSequencia(sequencia)">
                                <span class="d-block">
                                    {{sequencia.totalCarenciaIdade}}
                                </span>
                            </td>

                            <!-- tipo filiacao vinculo -->
                            <!-- <td class="align-middle">

                                <div class="dropdown" style="position: static;">
                                    <button class="btn btn-outline-default btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{sequencia.tipoVinculo | tipoVinculo}}
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.EMPREGADOOUAGENTEPUBLICO)">{{tiposVinculosType.EMPREGADOOUAGENTEPUBLICO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.CONTRIBUINTEINDIVIDUAL)">{{tiposVinculosType.CONTRIBUINTEINDIVIDUAL| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.BENEFICIO)">{{tiposVinculosType.BENEFICIO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.FACULTATIVO)">{{tiposVinculosType.FACULTATIVO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.EMPREGADODOMESTICO)">{{tiposVinculosType.EMPREGADODOMESTICO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.TRABALHADORAVULSO)">{{tiposVinculosType.TRABALHADORAVULSO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.SEGURADOESPECIAL)">{{tiposVinculosType.SEGURADOESPECIAL| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.SEGURADOFACULTATIVO)">{{tiposVinculosType.SEGURADOFACULTATIVO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.CONTRIBUINTEEMDOBRO)">{{tiposVinculosType.CONTRIBUINTEEMDOBRO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.AUTONOMO)">{{tiposVinculosType.AUTONOMO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.PENSAOMORTE)">{{tiposVinculosType.PENSAOMORTE| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.MEI)">{{tiposVinculosType.MEI| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.PRESTADORSERVICO)">{{tiposVinculosType.PRESTADORSERVICO| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.AUXILIOINCAPACIDADETEMPORARIA)">{{tiposVinculosType.AUXILIOINCAPACIDADETEMPORARIA| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.AUXILIOINCAPACIDADEPERMANENTE)">{{tiposVinculosType.AUXILIOINCAPACIDADEPERMANENTE| tipoVinculo}}</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.SALARIOMATERNIDADE)">{{tiposVinculosType.SALARIOMATERNIDADE| tipoVinculo}}</button></li>

                                    </ul>
                                </div>

                            </td> -->

                            <!-- tipo de atividade -->
                            <!-- <td class="align-middle">
                                <div class="dropdown" style="position: static;">
                                    <button class="btn btn-outline-default btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{sequencia.tipoAtividade | tipoAtividade}}
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Normal)">Normal</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Professor)">Professor</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Rural)">Rural</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Especial15)">Especial 15</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Especial20)">Especial 20</button></li>
                                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Especial25)">Especial 25</button></li>
                                    </ul>
                                </div>
                            </td> -->
                            <!-- <td class="align-middle">
                                {{sequencia.origemVinculo}}
                            </td> -->

                            <!-- opcoes -->
                            <td class="align-middle text-center ">
                                <div class="hstack gap-1 justify-content-center">
                                    <div class>
                                        <i class="bi bi-pen mx-1" role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasSequencia" aria-controls="canvasSequencia" (click)="abrirSequencia(sequencia)" container="body" ngbTooltip="Editar sequência"></i>
                                    </div>

                                    <div class>
                                        <div class="dropstart">
                                            <div class>
                                                <i class="bi bi-scissors mx-1" role="button" data-bs-auto-close="false" data-bs-toggle="dropdown" aria-expanded="false" container="body" ngbTooltip="Dividir sequência" (click)="abrirDividirSequencia(sequencia, 'datePicker'+index)"></i>

                                                <div class="dropdown-menu " [id]="'dropRecorte'+ index">

                                                    <div class="row p-4">
                                                        <div class>
                                                            <div class="vstack">
                                                                <div class="pb-4 text-right">
                                                                    <i class="bi bi-x-lg" role="button" (click)="fecharDropDividirSequencia('dropRecorte'+index)"></i>
                                                                </div>
                                                                <div class="recorte pb-4" style="max-width: 300px">
                                                                    <p class>
                                                                        Data de recorte da sequência {{sequencia.sequencia}}
                                                                    </p>
                                                                    <p class=" h6 fw-light">
                                                                        A sequência será dividida em duas partes. A primeira, com data de inicio original e data fim igual a data escolhida (inclusive). A segunda, terá data de início na data escolhida + 1 dia.
                                                                    </p>
                                                                </div>
                                                                <div class>
                                                                    <ejs-datepicker [id]="'datePicker'+index" [cssClass]="datepickerclass" [enableMask]="true" (change)="datePickerDataRecorte($event,sequencia, 'tesoura'+index)" [showClearbutton]="false" locale="pt"></ejs-datepicker>
                                                                </div>
                                                                <div class>
                                                                    <ejs-slider
                                                                        [id]="'tesoura'+index"
                                                                        [min]="0"
                                                                        [max]="sequencia | totalDiasSequencia"
                                                                        (tooltipChange)='tooltipChangeHandler($event, sequencia)'
                                                                        (created)="sliderTesouraCreated($event)"
                                                                        [showButtons]=true
                                                                        (change)="sliderChange($event, sequencia, 'datePicker'+index)"
                                                                        [tooltip]="tooltipSliderDividirVinculo" id='slider'></ejs-slider>
                                                                </div>
                                                                <div class="text-right">
                                                                    <i class="comando bi bi-scissors" role="button" (click)="recortar(sequencia, 'tesoura'+index, 'datePicker'+index)"></i>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class>
                                        <a class="text-default " [attr.aria-expanded]="sequencia.sequenciaInativa" data-bs-toggle="collapse" role="button" [href]="'#opcaoAtivarSequencia'+ sequencia.rowKey">
                                            <a class [id]="'opcaoAtivarSequencia'+sequencia.rowKey"></a>
                                            <i class=" bi bi-toggle-on mx-1" (click)="ativarSequencia(sequencia,false)" container="body" ngbTooltip="Desativar sequência" triggers="hover"></i>
                                            <i class=" bi bi-toggle-off mx-1" (click)="ativarSequencia(sequencia,true)" container="body" ngbTooltip="Ativar sequência" triggers="hover"></i>

                                        </a>
                                    </div>

                                    <div class>
                                        <i class="bi bi-trash mx-1" (click)="excluirSequencia(sequencia, index)" role="button" container="body" ngbTooltip="Excluir sequência"></i>
                                    </div>
                                </div>

                            </td>

                        </tr>
                    </ng-container>

                </tbody>
            </table>

        </div>

    </div>

</div>

<div class="row flex d-flex">
    <div class="col-6 py-2 flex d-flex">
        <div class="row bg-secondary rounded m-2 p-2 w-100">
            <div>

                <div class="pb-4">
                    <span class="h2 py-2">Total </span>
                </div>
                <div class="vstack gap-3">
                    <div>
                        <span class="d-block lh1 fw-lighter">Tempo de Contribuição </span>
                        <span class="d-block lh1 fw-light h3">{{calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoIdade.porExtenso}} </span>
                    </div>

                    <div>
                        <span class="d-block lh1 fw-lighter">Tempo de Contribuição em Dias </span>
                        <span class="d-block lh1 fw-light h3">{{calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoIdadeDias | number : '1.0-0'}} dias</span>
                    </div>

                    <div>
                        <span class="d-block lh1 fw-lighter">Carência </span>
                        <span class="d-block lh1 fw-light h3">{{calculoBeneficios.leituraCnis.cnis.totalCarenciaIdade}} </span>
                    </div>
                </div>
            </div>

        </div>

    </div>
    

    <div class="col-6 py-2  flex d-flex">
        <div class="row bg-secondary rounded m-2 p-2 w-100">
            <div>

                <div class="pb-4">
                    <span class="h2 py-2">Total Pré Reforma 13/11/2019 </span>
                </div>
                <div class="vstack gap-3">
                    <div>
                        <span class="d-block lh1 fw-lighter">Total de Tempo de Contribuição </span>
                        <span class="d-block lh1 fw-light h3">{{calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoPreReforma.porExtenso}} </span>
                    </div>

                    <div>
                        <span class="d-block lh1 fw-lighter">Tempo de Contribuição em Dias </span>
                        <span class="d-block lh1 fw-light h3">{{calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoPreReforma.totalDias | number : '1.0-0'}} dias</span>
                    </div>

                    <div>
                        <span class="d-block lh1 fw-lighter">Total de Carência </span>
                        <span class="d-block lh1 fw-light h3">{{calculoBeneficios.leituraCnis.cnis.totalCarenciaPreReforma}} </span>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>

<!-- canvas sequencia -->
<div class="offcanvas  offcanvas-bottom h-100" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasSequencia" aria-labelledby="canvasSequenciaLabel">
    <div class="offcanvas-header ml-6 mr-2 justify-content-between">
        <div *ngIf="sequencia" class="w-100 mr-4">
            <div class="row d-flex">
                <div class="col-auto ">
                    <span class="display-2 fw-lighter"> Sequência {{sequencia.sequencia}}</span>

                </div>
                <div class="col-auto p-0">
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpSequencia" aria-expanded="false" aria-controls="helpSequencia"></i>

                </div>
            </div>

            <div class="collapse" id="helpSequencia">
                <p class="lh-1 py-2">
                    Você está no Painel da Sequência. Utilize esta tela para visualizar a contagem de tempo de cada sequência com o quadro Timeline. No quadro Checklist, confira os critérios de validação que foram aplicados em cada
                    sequência/contribuição para considerar ou não para na contagem final de tempo de contribuição.
                </p>

            </div>
        </div>
        <!-- <span *ngIf="sequencia" class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Sequência {{sequencia.sequencia}}</span> -->
        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>
        <!-- <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    </div>
    <div class="offcanvas-body ml-6 mr-2">
        <div class="py-2">
            <app-canvas-sequencia *ngIf="sequencia" [(sequencia)]="sequencia" (atualizarCnisEvent)="atualizarCanvasSequencia($event)"></app-canvas-sequencia>
        </div>
        <div class="py-2">
            <app-canvas-contribuicoes *ngIf="sequencia" [(sequencia)]="sequencia" (atualizarCnisModificadoEvent)="canvasContribuicoesAtualizarCnisModificado($event)"></app-canvas-contribuicoes>

        </div>

    </div>

</div>



<!-- planilhas -->
<div class="offcanvas offcanvas-bottom h-100" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasPlanilhaContribuicoes" aria-labelledby="canvasPlanilhaContribuicoesLabel">

    <div class="offcanvas-header ml-6 mr-2 justify-content-between">
        <div class="w-100 mr-4">
            <div class="row d-flex">
                <div class="col-auto ">
                    <span class="display-2 fw-lighter">Planilhas</span>

                </div>
                <div class="col-auto p-0">
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpPlanilhas" aria-expanded="false" aria-controls="helpPlanilhas"></i>

                </div>
            </div>

            <div class="collapse" id="helpPlanilhas">
                <p class="lh-1 py-2">
                    Painel Planilhas
                </p>

            </div>
        </div>
        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>
        <!-- <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    </div>
    <div class="offcanvas-body ml-6 mr-2">
        <app-editor-contribuicoes-excel #planilhaContribuicoes *ngIf="carregarPlanilhas && calculoBeneficios.leituraCnis" [calculoBeneficios]="calculoBeneficios"></app-editor-contribuicoes-excel>
    </div>

</div>

<!-- validação-->
<div class="offcanvas offcanvas-bottom h-100" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasValidacao" aria-labelledby="canvasValidacao">
    <div class="offcanvas-header ml-6 mr-2 justify-content-between">
        <div class="w-100 mr-4">
            <div class="row d-flex">
                <div class="col-auto ">
                    <span class="display-2 fw-lighter">Validação</span>

                </div>
                <div class="col-auto p-0">
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpTempoContribuicao" aria-expanded="false" aria-controls="helpTempoContribuicao"></i>

                </div>
            </div>

            <div class="collapse" id="helpTempoContribuicao">
                <p class="lh-1 py-2">
                    Validação de contribuições para contagem de tempo de contribuição e carência
                </p>

            </div>
        </div>
        <!-- <span class="h3 mb-0 linha-quadro" id="canvasTempoContribuicaoLabel"></span> -->
        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>
        <!-- <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    </div>
    <div class="offcanvas-body ml-6 mr-2">
        <div class="pb-4">
            <!-- <app-canvas-tempo-contribuicao *ngIf="calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis && calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao" [calculoBeneficios]="calculoBeneficios"></app-canvas-tempo-contribuicao> -->
        </div>

        <div class="py-4">
            <!-- <app-validacoes-tempo-contribuicao *ngIf="calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis && calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao" [calculoBeneficios]="calculoBeneficios"></app-validacoes-tempo-contribuicao> -->
            <app-validacao-tempo-contribuicao-carencia *ngIf=" carregarValidacao && calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis" [calculoBeneficios]="calculoBeneficios"></app-validacao-tempo-contribuicao-carencia>
        </div>

    </div>
</div>

<!--  indicadores -->
<div class="offcanvas offcanvas-bottom h-100" data-bs-backdrop="static" data-bs-scroll="true" tabindex="-1" id="canvasIndicadores" aria-labelledby="canvasIndicadoresLabel">
    <div class="offcanvas-header ml-6 mr-2 justify-content-between">
        <div class="w-100 mr-4">

            <div class="row d-flex">
                <div class="col-auto ">
                    <span class="display-2 fw-lighter">Indicadores</span>

                </div>
                <div class="col-auto p-0">
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpIndicadores" aria-expanded="false" aria-controls="helpIndicadores"></i>

                </div>
            </div>

            <div class="collapse" id="helpIndicadores">
                <p class="lh-1 py-2">
                    Painel Indicadores
                </p>

            </div>
        </div>
        <!-- <div *ngIf="sequencia" class="w-100 linha-quadro">Indicadores seq. {{sequencia.sequencia}}</div> -->
        <!-- <span *ngIf="sequencia" class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Indicadores seq. {{sequencia.sequencia}}</span> -->
        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>
        <!-- <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    </div>
    <div class="offcanvas-body ml-6 mr-2">
        <app-canvas-indicadores *ngIf="carregarIndicadores && calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis" [calculoBeneficios]="calculoBeneficios"></app-canvas-indicadores>
    </div>

</div>
<!--  pontos de atenção -->
<div class="offcanvas offcanvas-bottom  h-100" data-bs-backdrop="static" data-bs-scroll="true" tabindex="-1" id="canvasPontosAtencao" aria-labelledby="canvasPontosAtencaoLabel">
    <div class="offcanvas-header ml-6 mr-2 justify-content-between">
        <div class="w-100 mr-4">
            <div class="row d-flex">
                <div class="col-auto ">
                    <span class="display-2 fw-lighter">Pontos de Atenção</span>

                </div>
                <div class="col-auto p-0">
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpPontoAtencao" aria-expanded="false" aria-controls="helpPontoAtencao"></i>
                </div>
            </div>

            <div class="collapse" id="helpPontoAtencao">
                <p class="lh-1 py-2">
                    Você está no Painel Pontos de Atenção
                </p>

            </div>
        </div>

        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>

    </div>
    <div class="offcanvas-body ml-6 mr-2">
        <div class="py-4">
            <app-validacoes-pontos-atencao *ngIf="carregarPontosAtencao && calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis && calculoBeneficios.leituraCnis.cnis.fluxoPontosAtencao" [calculoBeneficios]="calculoBeneficios"></app-validacoes-pontos-atencao>
        </div>

    </div>

</div>

<!-- nao utilizados -->

<!-- canvas tempo contribuicao -->
<div class="offcanvas offcanvas-bottom h-100" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasTempoContribuicao" aria-labelledby="canvasTempoContribuicaoLabel">
    <div class="offcanvas-header ml-6 mr-2 justify-content-between">
        <div class="w-100 mr-4">
            <div class="row d-flex">
                <div class="col-auto ">
                    <span class="display-2 fw-lighter">Tempo de Contribuição</span>

                </div>
                <div class="col-auto p-0">
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpTempoContribuicao" aria-expanded="false" aria-controls="helpTempoContribuicao"></i>

                </div>
            </div>

            <div class="collapse" id="helpTempoContribuicao">
                <p class="lh-1 py-2">
                    Você está no Painel de Tempo de Contribuição. Utilize esta tela para visualizar a contagem de tempo de cada sequência com o quadro Timeline. No quadro Checklist, confira os critérios de validação que foram aplicados em cada
                    sequência/contribuição para considerar ou não para na contagem final de tempo de contribuição.
                </p>

            </div>
        </div>
        <!-- <span class="h3 mb-0 linha-quadro" id="canvasTempoContribuicaoLabel"></span> -->
        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>
        <!-- <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    </div>
    <div class="offcanvas-body ml-6 mr-2">
        <div class="pb-4">
            <!-- <app-canvas-tempo-contribuicao *ngIf="calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis && calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao" [calculoBeneficios]="calculoBeneficios"></app-canvas-tempo-contribuicao> -->
        </div>

        <div class="py-4">
            <!-- <app-validacoes-tempo-contribuicao *ngIf="calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis && calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao" [calculoBeneficios]="calculoBeneficios"></app-validacoes-tempo-contribuicao> -->
        </div>

    </div>
</div>

<!-- canvas contribuicoes -->
<div class="offcanvas offcanvas-bottom h-100" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasContribuicoes" aria-labelledby="canvasContribuicoesLabel">
    <div class="offcanvas-header ml-6 mr-2 justify-content-between">

        <div *ngIf="sequencia" class="w-100 mr-4">
            <div class="row d-flex">
                <div class="col-auto ">
                    <span class="display-2 fw-lighter"> Contribuições seq. {{sequencia.sequencia}}</span>

                </div>
                <div class="col-auto p-0">
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpContribuicoesSequencia" aria-expanded="false" aria-controls="helpContribuicoesSequencia"></i>

                </div>
            </div>

            <div class="collapse" id="helpContribuicoesSequencia">
                <p class="lh-1 py-2">
                    Painel Contribuições de Sequência
                </p>

            </div>
        </div>
        <!-- <span *ngIf="sequencia" class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Contribuições seq. {{sequencia.sequencia}}</span> -->
        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>
        <!-- <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    </div>
    <div class="offcanvas-body ml-6 mr-2">
        <!-- <app-canvas-contribuicoes *ngIf="sequencia" [(sequencia)]="sequencia"></app-canvas-contribuicoes> -->
    </div>
</div>



<!-- canvas carencia  -->
<div class="offcanvas offcanvas-bottom h-100" data-bs-backdrop="static" data-bs-scroll="true" tabindex="-1" id="canvasCarencia" aria-labelledby="canvasCarenciaLabel">
    <div class="offcanvas-header ml-6 mr-2 justify-content-between">
        <div class="w-100 mr-4">
            <div class="row d-flex">
                <div class="col-auto ">
                    <span class="display-2 fw-lighter">Carência</span>

                </div>
                <div class="col-auto p-0">
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpCarencia" aria-expanded="false" aria-controls="helpCarencia"></i>

                </div>
            </div>

            <div class="collapse" id="helpCarencia">
                <p class="lh-1 py-2">
                    Você está no Painel de Carência. Utilize esta tela para visualizar a contagem de carência com o quadro Timeline. No quadro Checklist, confira os critérios de validação que foram aplicados em cada
                    contribuição para considerar ou não para na contagem final de carência.
                </p>

            </div>
        </div>
        <!-- <span class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Contagem de Carência</span> -->
        <i role="button" class="comando bi bi-x fs-6 px-0 mx-0" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>
        <!-- <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    </div>
    <div class="offcanvas-body ml-6 mr-2">

        <div class="py-4">
            <!-- <app-canvas-carencia *ngIf="calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis && calculoBeneficios.leituraCnis.cnis.fluxoCarencia" [calculoBeneficios]="calculoBeneficios"></app-canvas-carencia> -->
        </div>
        <div class="py-4">
            <!-- <app-validacoes-carencia *ngIf="calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis && calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao" [calculoBeneficios]="calculoBeneficios"></app-validacoes-carencia> -->
        </div>
    </div>

</div>
